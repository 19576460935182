@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNavWrapper($bgColor: $white);

@include MobileNav(
  $isSelectedBorderLeft: $primary40,
  $listItemColor: $primary40,
  $fontSize: 1rem,
  $isSelectedBg: $secondary90,
  $listBgColor: $secondary95
) {
  @include Chevron(
    $className: chevron--mobile,
    $chevronColor: $primary40,
    $lineLength: 7px,
    $leftLinePosition: calc(100% - 24px),
    $rightLinePosition: calc(100% - 20px)
  ) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;

    outline-offset: -6px;
    outline-color: $primary40;
  }

  .c-mobilenav__list__item {
    box-shadow: inset 0 3px 4px 0 rgba($black, 0.2);

    a {
      @include focus-handler($color: $primary40, $offset: -2px);
      font-family: $secondary-font;
    }
  }
}

@include MobileNavSubLevel(
  $listItemBorderLeftColor: c('secondary40'),
  $isOpenBorderLeft: c('secondary40'),
  $hasBorder: false,
  $isOpenBg: $secondary95,
  $borderOnHoverColor: $primary40,
  $textColor: $primary40,
  $fontSize: 1rem,
  $sublevelBoxShadow: inset 0 3px 4px 0 rgba($black, 0.2)
) {
  font-weight: normal;

  .c-mobilenav__list__item {
    box-shadow: inset 0 3px 4px 0 rgba($black, 0.2);

    a {
      @include focus-handler($offset: -2px);
    }
  }

  @include Chevron(
    $className: 'chevron--mobile',
    $chevronColor: $primary40,
    $lineLength: 7px,
    $leftLinePosition: calc(100% - 24px),
    $rightLinePosition: calc(100% - 20px)
  ) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    @include focus-handler($offset: -8px);
  }
  @include SubLevelIndent($textIndent: 60px) {
    // sublevel 2

    @include SubLevelIndent($textIndent: 90px) {
      // sublevel 3

      @include SubLevelIndent($textIndent: 110px);
      // sublevel 4
    }
  }
}
