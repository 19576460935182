@import '../../platformui/Mobilenav/expand-button/styles';

@include ExpandButton(
  $className: 'plus--extended-hours',
  $topPosition: 3px,
  $bgColorBtn: $accent40,
  $btnHeight: 17px,
  $btnWidth: 17px,
  $btnBorderRadius: 100%,
  $lineThickness: 1px,
  $lineSize: 9px,
  $marginCentered: -5px
) {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left: 5px;
  }
  right: auto;
}
