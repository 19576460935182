@mixin AccessibilityNav($bgColor: #fff, $color: #000) {
  .c-accessibility-nav {
    display: block;
    height: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    li {
      height: 0;
      margin: 0;
      padding: 0;
      a {
        display: block;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        position: absolute;
        text-align: center;
        text-decoration: underline;
        text-indent: 150%;
        top: 0;
        transition: opacity 0.2s linear;
        white-space: nowrap;
        width: 1px;
        z-index: 99999;

        &:focus {
          background: $bgColor;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
          color: $color;
          font-size: rem-calc(16);
          height: auto;
          line-height: 20px;
          opacity: 1;
          outline: none;
          padding: 0.75rem;
          text-indent: 0;
          width: 100%;
        }
      }
    }
    @content;

    @media print {
      display: none;
    }
  }
}
