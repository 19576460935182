@import '../../platformui/Video/styles';

@include Video(
  $marginBottom: 30px,
  $captionMargin: 11px 0 36px,
  $captionUnderlineColor: $neutral20,
  $captionUnderlineHeight: 1px,
  $h2Color: $neutral20,
  $captionFontSize: 14px
);
