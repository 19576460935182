@use 'sass:map';

@mixin VideoContainer(
  $blockBackground,
  $descriptionColor,
  $headingColor,
  $headerSize,
  $iconColor,
  $iconContent,
  $linkColor,
  $baseMaxWidth: rem-calc(1048px)
) {
  .c-video-container-wrapper {
    $block: &;

    overflow: hidden; // make child elements' margins be included in size

    &--start-page,
    &--collection-page-standalone {
      padding-inline: rem-calc(20px);

      @include breakpoint(medium) {
        padding-inline: rem-calc(10px);
      }
    }

    &--start-page {
      > .c-video-container {
        max-width: 980px;
      }
    }

    &--collection-page-standalone {
      > .c-video-container {
        max-width: $baseMaxWidth;

        @include breakpoint(medium) {
          max-width: calc(2 * 100% / 3);
        }

        @include breakpoint(large) {
          max-width: 1048px;
        }
      }
    }
  }

  .c-video-container {
    $block: &;
    $aspectRatio-standing: 9 / 16;
    $aspectRatio-landscape: 16 / 9;

    container-type: inline-size;
    margin-block: 20px;
    margin-inline: auto;

    @include breakpoint(large) {
      margin-block: 60px;
    }

    &__block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      width: 100%;
      border-radius: rem-calc(10);
      overflow: hidden;
      background: $blockBackground;

      .c-video__wrapper {
        margin: 0px;
        width: 100%;
        aspect-ratio: $aspectRatio-landscape;
        flex: 0 0 auto;

        #{$block}--standing & {
          aspect-ratio: $aspectRatio-standing;
        }
      }

      @container (min-width: 500px) {
        // Make standing videos shift to the left side

        #{$block}--standing & {
          flex-direction: row;
          align-items: center;

          .c-video__wrapper {
            align-self: flex-start;
            flex: 0 1 40%;
            max-width: 280px;
          }
        }
      }

      @container (min-width: 700px) {
        // Make landscape videos shift to the left side

        flex-direction: row;
        align-items: center;

        .c-video__wrapper {
          align-self: flex-start;
          flex: 0 1 619px;
          max-width: 619px;
        }
      }

      .c-video {
        // clear all styling for video so we can make it responsive
        width: 100%;
        height: 100%;
        margin: 0px;

        &__container,
        &.standing > .c-video__container {
          width: 100%;
          height: 100%;
          min-width: auto;
          padding-top: 0;
        }
      }
    }

    &__textarea {
      text-wrap: wrap;
      padding-inline: 20px;
      padding-block: 25px;
      flex: 1 1 calc(100% / 3);

      @container (min-width: 768px) {
        padding-inline: 40px;

        #{$block}--standing & {
          padding-inline: 80px;
        }
      }

      &__heading {
        --header-font-size: #{map.get($headerSize, default, landscape)};
        min-width: 12ch;

        overflow-wrap: break-word;
        color: $headingColor;
        font-size: var(--header-font-size);
        margin: 0 0 10px;

        @if (map.has-key($headerSize, default, standing)) {
          #{$block}--standing & {
            --header-font-size: #{map.get($headerSize, default, standing)};
          }
        }

        @if (map.has-key($headerSize, large)) {
          @include breakpoint(medium) {
            --header-font-size: #{map.get($headerSize, large, landscape)};
          }

          @include breakpoint(large) {
            #{$block}--standing & {
              --header-font-size: #{map.get($headerSize, large, standing)};
            }
          }
        }
      }

      &__description {
        color: $descriptionColor;
        font-size: rem-calc(16);
        margin: 0;
      }

      &__link {
        display: block;
        position: relative;
        color: $linkColor;
        font-size: rem-calc(16);
        margin-top: 10px;
        text-decoration: none;

        &:hover {
          text-decoration: none;

          span {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        &::before {
          content: $iconContent;
          font-family: 'fontello';
          padding-right: 10px;
          color: $iconColor;
        }
      }
    }
  }

  @content;
}
