@import '../../platformui/AnchorNav/styles';

@include AnchorNav(
  $mainColor: $neutral90,
  $fontColor: $accent40,
  $focusColor: $neutral20
);

.anchor-nav {
  &__inner {
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba($black, 0.3);
    border: 1px solid rgba($black, 0.3);
  }
  &__navigation {
    padding-top: 20px;
    border: none;
  }

  &:before {
    left: -11px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-bottom-color: rgba($black, 0.3);
    border-left-color: rgba($black, 0.3);
    box-shadow: -3px 3px 5px -1px rgba($black, 0.15);
    top: 51px;
  }

  &__heading {
    @include base-font-family();
    text-transform: uppercase;
    font-weight: normal;
    color: $neutral20;
    letter-spacing: 0.063em;
    font-size: 1rem;
    border-bottom: 1px solid $neutral90;
  }

  &__list {
    li {
      border-bottom: 1px solid $neutral90;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    &__link {
      @include link-arrow(
        $color: $accent40,
        $hoverColor: $white,
        $paddingLeft: 32px,
        $top: 6px,
        $left: 5px
      ) {
        &:before {
          left: 3px;
          font-size: 20px;
          top: 3px;
        }

        &:after {
          height: 25px;
          top: 1px;
          width: 25px;
        }
      }
      &--is-selected {
        &:before {
          color: $white;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
