@import '../../platformui/ArticleFooter/styles';

@include ArticleFooter($background: $white, $padding: 0) {
  .c-articlefooter {
    &__value {
      color: $neutral20;
    }
    &:first-of-type {
      margin: 90px 0 0 0;

      .c-articlefooter__wrapper {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          background-color: $neutral40;
          height: 1px;
          width: 43px;
          left: 0;
          top: -30px;

          @include breakpoint(large) {
            content: none;
          }
        }
      }

      @include breakpoint(large) {
        margin: 20px 0 30px 0;
      }
    }

    margin: 20px 0 30px 0;
    @include breakpoint(large) {
      margin: 0 0 30px;
    }
    &__key {
      font-weight: bold;
      color: $neutral20;
      display: block;
    }
    &__content {
      @media print {
        font-size: 8px !important;
        page-break-inside: avoid;
      }
    }
  }
}
