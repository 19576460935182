@import '../../platformui/Teaser/styles';
@import './teaser-linklist.scss';

@include Teaser(
  $headingLinkColor: $accent40,
  $vignetteColor: $accent40,
  $headingIconColor: $accent40,
  $headingPadding: 0 0 10px 0,
  $headingMargin: 0 0 10px 0,
  $componentBoxShadow: none,
  $componentBorderRadius: (
    3px,
    3px,
    3px,
  ),
  $componentPageListBorderBottom: 10px solid $secondary95,
  $componentPageListBorderBottomHover: 10px solid $secondary95,
  $outerMargin: 20px,
  $eqHeightPadding: 20px 20px 20px
) {
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: $secondary95;

  &--eq-height {
    height: 100%;
    .c-teaser__content {
      .c-teaser__text {
        height: 100px;
      }
    }
  }

  @include breakpoint(medium) {
    &--eq-height {
      height: 100%;
    }
  }

  &-outer {
    &:last-child:not(:first-child) {
      float: left;
    }
  }

  &__heading {
    @include secondary-font-family();
    font-size: rem-calc(18);
    border-bottom: solid 1px $neutral40;

    &--no-text {
      border-bottom: none;
    }
    &__link:before {
      color: $accent40;
      content: '\f105';
    }
  }

  &__text {
    margin-left: 10px;
    margin-right: 10px;
    p {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 120px;
      color: $neutral20;
    }
  }
}
