@mixin LinkItemList($borderColor: red, $spanColor: purple) {
  .link-item-list {
    display: block;
    list-style: none;
    margin-top: 20px;
    padding: 0;

    @include breakpoint(medium) {
      margin: 40px 0;
    }

    &__ctrls {
      margin-bottom: 60px;
      min-height: 40px;
      position: relative;
      text-align: center;

      @include WithSectionWrapper() {
        display: flex;
        justify-content: center;
      }
    }

    &__link {
      text-decoration: none;

      &:hover {
        span {
          border-color: $borderColor;
        }
      }
      span {
        border-bottom: 1px solid rgba($borderColor, 0.4);
        color: $spanColor;
        display: inline;
      }

      @include breakpoint(large) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @content;
  }
}
