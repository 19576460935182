.c-formfield {
  width: 100%;
  background-color: c('light-grey');
  border-bottom: 2px solid c('accent40');
  border-left: 1px solid c('muted');
  border-right: 1px solid c('muted');
  border-top: 1px solid c('muted');
  font-size: 1rem;

  border-radius: 4px;

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  box-shadow: none;
  box-sizing: border-box;
  color: c('neutral20');
  min-height: 60px;
  padding: 15px 20px;

  &--has-focus {
    @include focus-style();
  }

  &--focus,
  &:focus {
    @include focus-style();
    box-shadow: 0 0 15px 0 rgba(c('neutral20'), 0.4);
  }

  &--disabled:focus,
  &--disabled,
  &[disabled],
  &[readonly],
  &[readonly]:focus,
  &:disabled {
    border-color: c('muted');
    background-color: c('light-grey');
    color: c('medium-grey');
    box-shadow: 0 0 15px 0 rgba(c('neutral20'), 0.4);
    opacity: 1;
  }

  &--error:focus,
  &--error {
    border-style: dashed;
    border-color: c('error40');
    background-color: c('error99');
  }
  &--error:focus {
    box-shadow: 0 0 15px 0 rgba(c('neutral20'), 0.4);
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: c('medium-grey');
    font-style: normal;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: c('medium-grey');
    opacity: 1;
    font-style: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: c('medium-grey');
    opacity: 1;
    font-style: normal;
  }

  &::placeholder {
    color: c('medium-grey');
    opacity: 1;
    font-style: normal;
  }
}
