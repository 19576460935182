@mixin Header(
  $className: 'c-header',
  $height: (
    50px,
    105px,
    150px,
  )
) {
  .#{$className} {
    position: relative;
    z-index: 3;

    &--showbuildinfo {
      padding-top: rem-calc(37);
    }

    &__top {
      box-shadow: 0 2px 4px 0 rgba($black, 0.2);
      float: left;
      min-height: nth($height, 1);
      transition: all 0.3s ease;
      position: relative;
      width: 100%;

      @media print {
        min-height: auto !important;
        height: 90px;
      }

      @include breakpoint(medium) {
        min-height: nth($height, 2);
      }

      @include breakpoint(large) {
        box-shadow: none;
      }

      &:after {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        @include breakpoint(large) {
          box-shadow: none;
        }
      }

      &__inner {
        min-height: nth($height, 1);
        position: relative;
        z-index: 2;

        @media print {
          min-height: auto !important;
          height: 90px;
        }

        @include breakpoint(medium) {
          min-height: nth($height, 2);
        }

        @include breakpoint(large) {
          min-height: nth($height, 3);
        }
      }
    }

    &__main {
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 7px;
      max-width: rem-calc(1280);
      width: 100%;
      z-index: 2;

      @media print {
        padding: 0 !important;
      }

      @include breakpoint(large) {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
        width: 100%;
      }
    }

    &__logo {
      display: block;
      float: left;
      position: relative;
      z-index: 3;

      @media print {
        margin-top: 5px !important;
      }

      @include breakpoint(medium) {
        height: 53px;
        margin-top: 26px;
      }

      img,
      svg {
        height: 100%;
        object-fit: contain;
        position: relative;
        top: -2px;
        width: 100%;
      }
    }

    &__navigation {
      clear: both;
      &--is-full-overlay {
        .c-mobilenav {
          &-wrapper {
            position: fixed;
            z-index: 999;
            width: 100%;
            height: 100vh;
            overflow: scroll;
          }

          .c-header__burger > .lines-button {
            top: -5px;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 20px 30px 20px;

            h2 {
              font-size: 1.625rem;
              margin: 0;
            }
          }
        }
      }
    }

    &__message {
      padding: 25px 0 0;

      .c-alert {
        margin-bottom: 0;

        &:not(:first-of-type) {
          margin-top: 25px;
        }
      }
    }

    //Modifiers
    &--is-navigation {
      .c-header__top {
        @include breakpoint(large) {
          position: relative;
        }
      }
    }

    &--visible {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      transition: all 0.3s linear;
    }

    &--hidden {
      position: fixed;
      top: -60px;
      transition: all 0.3s linear;
    }

    @content;
  }
}
//This is to hide the browsealoud button that appears under the footer. Keywords: ba, talandewebb, browsealoud.
#__ba_panel {
  display: none;
}
