@import '../../platformui/SearchArticleList/styles.scss';

@include SearchArticleList(
  $dateTextColor: $neutral20,
  $dateTextSize: rem-calc(14)
) {
  .c-search-list {
    .search-list {
      &__date {
        margin-bottom: 10px;
      }

      &__content {
        max-height: none;
      }

      &__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__item p {
        max-height: none;
      }

      .link-item-list__button {
        span {
          color: $accent40;
        }
        border-color: $accent40;
      }
    }
  }
} ;
