.toggle-chapter {
  border-bottom: 1px solid rgba($neutral40, 0.7);

  @include breakpoint(large) {
    border: none;
  }
  &__button {
    align-items: center;
    background: none;
    display: flex;
    padding: 15px 0;
    position: relative;
    text-align: left;
    width: 100%;

    [dir='rtl'] & {
      text-align: right;
    }

    @include focus-handler($color: $neutral20, $offset: 2px);

    @include breakpoint(large) {
      display: none;
    }
  }

  &__heading {
    @include secondary-font-family();
    margin: 0;
    margin-right: 40px;
    color: $accent40;
    font-size: rem-calc(28);
    font-weight: bold;
    line-height: 36px;

    @include breakpoint(large) {
      margin: 20px 0 10px;
    }
    @media print {
      font-size: 22pt !important;
    }

    [dir='rtl'] & {
      padding-left: 45px;
      padding-right: unset;
      text-align: right;
    }
  }

  &__wrapper {
    height: 0;
    overflow: hidden;

    @include breakpoint(large) {
      height: auto;
      overflow: visible;
      display: block;
    }

    &--is-open {
      height: auto;
      overflow: unset;
      outline: transparent;
    }
  }
}
