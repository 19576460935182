@import '../../platformui/ListContainer/styles';

@include ListContainer($headingColor: $neutral20) {
  .list-container--has-section-wrapper {
    margin: 0;
    & .c-section__inner {
      border: none;
      padding: 0;
    }
  }
}
