@import '../../platformui/SearchLinkList/styles';
@import '../../platformui/SearchLinkList/item-list.scss';
@import '../../platformui/SearchLinkList/item.scss';
@import '../../platformui/Buttons/button.scss';

@include SearchLinkList($borderColor: $neutral20, $headingColor: $neutral20) {
  margin: 20px 10px;
  @include breakpoint(large) {
    margin: 20px 0;
  }
  &__heading {
    color: $neutral20;
    font-size: 1rem;
  }
  &__btn {
    @include focus-handler();
  }

  .chevron--search-link {
    &:before {
      left: calc(100% - 31px);
    }
    &:before,
    &:after {
      background-color: $accent40;
      height: 17px;
      width: 4px;
    }
  }
}
@include LinkItemList($borderColor: $accent40, $spanColor: $accent40) {
  @include breakpoint(medium) {
    margin: 20px 0 0;
  }
  &__ctrls {
    @include breakpoint(medium) {
      margin: 40px 0 0;
      display: flex;
      justify-content: center;
    }
    div {
      width: 100%;
      @include breakpoint(medium) {
        width: unset;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  &__link {
    @include focus-handler();
    top: 50%;
    span {
      border-bottom: none;
      font-weight: bold;
    }
    &:hover {
      border-bottom: 1px solid $accent40;
    }
  }

  &__button {
    @extend .c-formbutton;
    @extend .c-formbutton--secondary;
  }
}
@include SearchLinkItem(
  $borderColor: $neutral20,
  $oddBgColor: $secondary95,
  $color: $accent40,
  $iconSize: 120%,
  $iconTopMargin: 3px,
  $iconColor: $accent40,
  $paddingWithSectionWrapper: 25px
) {
  .search-link-item {
    &__link {
      @include icon('F105');
      @include focus-handler();
      &:before {
        font-weight: bold;
      }

      & span {
        border: none;
        font-weight: bold;
        @include breakpoint(medium) {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }
}
