.error500 {
  min-height: 100vh;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  padding: 0;

  // Overwrite main display style
  &.main {
    display: flex;
    max-width: 100%;
  }

  a {
    color: c('primary40');
  }

  .preamble {
    max-width: 620px;
    margin: 0 auto;
    font-size: 1.25rem;

    p {
      font-size: inherit;
    }
  }

  .row.first-row {
    position: relative;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;

    .svg-container {
      height: auto;
      width: 200px;
      padding: 40px;

      @include breakpoint(large) {
        padding-right: 25px;
        svg {
        }
      }
    }
  }
}
