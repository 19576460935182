@import '../../../platformui/TableWrapper/styles';

.table-wrapper {
  @media print {
    margin-left: unset !important;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  clear: both;
  margin-bottom: rem-calc(30);
  min-width: 100%;
  color: $neutral20;

  @media print {
    color: black;
    border-collapse: collapse;
  }

  .table-wrapper & {
    margin-bottom: rem-calc(10);
  }

  caption {
    color: rgba($black, 0.6);
    font-size: rem-calc(18);
    padding: rem-calc(14) 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    border-top: 1px solid $neutral40;
    background: $white;

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      font-size: rem-calc(12);
      color: $black;
      border-color: $black;
    }
  }
  thead {
    td,
    th {
      border-top: 1px solid $neutral40;
    }
  }
  thead,
  tbody {
    td,
    th {
      border-top: 1px solid $neutral40;
      border-right: 1px solid $neutral40;

      &:first-child {
        border-left: 1px solid $neutral40;
      }

      &:last-child {
        border-right: 1px solid $neutral40;
      }

      @media print {
        border-color: $black;
        font-size: rem-calc(10);
      }
    }
  }

  thead th {
    @include secondary-font-family();
    background: $secondary90;
    font-weight: bold;
    white-space: nowrap;
    vertical-align: top;
    line-height: 22px;
    @media print {
      background-color: transparent;
      border-bottom: 1px solid;
      border-right: none;
      border-color: $black;
    }

    &:first-child {
      border-top-left-radius: 10px;

      [dir='rtl'] & {
        border-top-left-radius: 0;
        border-top-right-radius: 10px;
      }
    }

    &:last-child {
      border-top-right-radius: 10px;

      [dir='rtl'] & {
        border-top-right-radius: 0;
        border-top-left-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: rem-calc(7) rem-calc(20);
        @media print {
          padding-left: rem-calc(10);
          padding-right: rem-calc(10);
        }
      }
    }
    tr:last-of-type th,
    tr:last-of-type td {
      border-bottom: 1px solid $neutral40;
      &:first-child {
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
      }
      @media print {
        border-color: $black;
      }
    }
  }

  tfoot {
    td {
      font-size: rem-calc(14);
      padding: rem-calc(7) rem-calc(0) rem-calc(10);
      position: relative;
      color: $neutral20;

      @media print {
        font-size: 8px;
      }

      p {
        font-size: inherit;
      }
    }
  }

  th {
    font-weight: normal;
    padding: rem-calc(10) rem-calc(20);
    @media print {
      padding-left: rem-calc(10);
      padding-right: rem-calc(10);
    }
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }
  }

  &.wide {
    background: $white;

    @if $editor == 'true' {
      editmenuname: Utfallande tabell;
    }
  }
}

@include TableWrapper($scrollbarColor: $accent40) {
  margin-left: -30px;
}
