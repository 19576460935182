@import '../../platformui/ReadingAid/styles.scss';
@import './reading-aid-dynamic.scss';

$open: '.c-reading-aid--is-opened';

@include ReadingAid($activateButtonBg: $accent40) {
  background-color: c('white');
  box-shadow: 0 0 10px 0 rgba(c('neutral20'), 0.3);
  max-width: 100%;
  position: relative;

  & > div {
    flex: 0 0 100%;
  }

  .columns {
    margin: 0;
  }

  &__inner {
    opacity: 0;
    position: relative;
    transition: opacity 0.2s linear 0.1s;
    padding-left: 0;
    padding-right: 0;

    #{$open} & {
      opacity: 1;
    }

    p {
      max-width: 560px;
      margin: 0;
    }

    & > div {
      width: auto;
      min-width: 600px;
      flex-grow: 0;
    }

    &:after {
      border-top: 15px solid $white;
      border-bottom: 15px solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      content: '';
      filter: drop-shadow(-1px 3px 2px rgba(30, 30, 30, 0.15));
      height: 0;
      position: absolute;
      right: 60px;
      top: 127px;
      width: 0;
    }
  }

  button {
    min-height: 50px;
    font-size: 1.25rem;
    border-radius: 3px;
    border: none;
    font-weight: normal;
    min-width: 70px;
    padding: 13px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    // fallback for when flex is not supported
    display: inline-block;

    border-radius: 4px;
    color: c('white');
    background-color: c('accent40');
    font-weight: bold;
    font-size: 1.5rem;

    flex-grow: 1;

    &:hover {
      background-color: c('accent30');
    }
  }
}
