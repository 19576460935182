@import './color-palette.scss';

h1 {
  @include secondary-font-family();
  font-size: rem-calc(40);
  font-weight: bold;
  line-height: 50px;
  color: $primary40;
  margin: 20px 0;
  @include breakpoint(large) {
    margin: 20px 0 20px;
  }

  @media print {
    font-size: 24pt !important;
  }
}

h2 {
  @include secondary-font-family();
  color: $primary40;
  font-size: rem-calc(28);
  font-weight: bold;
  line-height: 36px;
  margin: 20px 0 8px;

  @include breakpoint(large) {
    margin: 20px 0 10px;
  }
  @media print {
    font-size: 22pt !important;
  }
}

h3 {
  @include secondary-font-family();
  color: $neutral20;
  font-size: rem-calc(22);
  font-weight: bold;
  line-height: 28px;
  margin: 10px 0 6px;
  @media print {
    font-size: rem-calc(15) !important;
  }
}

h4 {
  @include secondary-font-family();
  color: $neutral20;
  font-size: rem-calc(18);
  font-weight: bold;
  line-height: 26px;
  margin: 0 0 6px;
  @media print {
    font-size: rem-calc(12) !important;
  }
}

@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
  h2,
  h3,
  h4 {
    margin: 30px 0 rem-calc(8) !important;
  }
}

.preamble {
  font-size: rem-calc(20);
  font-weight: 300;
  line-height: 32px;
  margin: 0 0 30px;
  color: $neutral20;

  @media print {
    font-size: 16pt !important;
    line-height: 1.5 !important;
    width: 95% !important;
  }

  [lang='rmn'] &,
  [lang='pl-PL'] &,
  [lang='rmf'] &,
  [lang='se-SE'] &,
  [lang='rmy-KA'] &,
  [lang='rmy-LO'] &,
  [lang='sr'] &,
  [lang='bs'] &,
  [lang='hr'] &,
  [lang='tr-TR'] &,
  [lang='kmr'] &,
  [lang='vi'] & {
    font-weight: 400;
  }

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 30px 0 0;

    &:first-child {
      margin: 0;
    }
    @include breakpoint(large) {
      margin: 40px 0 0;
    }
  }
}

p {
  font-size: rem-calc(16);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin: 0 0 20px;
  color: $neutral20;

  [dir='rtl'] & {
    font-size: rem-calc(18);
  }
}

a {
  color: $accent40;

  &:hover {
    color: $accent30;
  }
  &:active {
    color: $accent30;
  }
}

ul,
ol {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    list-style: none;
    overflow: hidden;
    list-style-position: outside;
    margin: 0 0 rem-calc(10);

    [dir='rtl'] & {
      font-size: rem-calc(18);
    }

    li {
      padding-bottom: rem-calc(10);
    }
  }
  &:before {
    content: '\200B';
    position: absolute;
  }
}

ul {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    padding: 0 0 0 rem-calc(32);

    [dir='rtl'] & {
      padding: 0 rem-calc(32) 0 0;
    }

    li {
      padding-left: rem-calc(6);
      position: relative;
      text-indent: rem-calc(1);

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: rem-calc(6);
      }

      @include breakpoint(medium) {
        padding-left: rem-calc(15);

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(15);
        }
      }
      &:before {
        background: $primary30;
        border-radius: 50%;
        content: '\200B';
        display: block;
        height: rem-calc(8);
        left: 0;
        margin-left: rem-calc(-25);
        margin-right: rem-calc(15);
        position: relative;
        top: rem-calc(16);
        width: rem-calc(8);

        [dir='rtl'] & {
          margin-left: rem-calc(15);
          margin-right: rem-calc(-25);
          left: auto;
          right: 0;
        }

        @include breakpoint(medium) {
          left: rem-calc(-2);

          [dir='rtl'] & {
            left: auto;
            right: rem-calc(-2);
          }
        }

        @media print {
          content: none;
        }
      }
    }
  }
}

ol {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    counter-reset: item;
    padding: 0 0 0 rem-calc(34);

    [dir='rtl'] & {
      padding: 0 rem-calc(34) 0 0;
    }

    li {
      counter-increment: item;
      padding-left: rem-calc(6);
      text-indent: rem-calc(-2);

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: rem-calc(6);
      }

      @include breakpoint(medium) {
        padding-left: rem-calc(15);

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(15);
        }
      }
      &:before {
        display: inline-block;
        font-weight: bold;
        margin-left: rem-calc(-25);
        margin-right: rem-calc(15);
        position: relative;
        content: counter(item) '.';

        [dir='rtl'] & {
          margin-left: rem-calc(15);
          margin-right: rem-calc(-25);
        }
      }
    }
  }
}

@import '../../../platformui/buttons/button.scss';

@include button(
  $bg: $white,
  $border: 1px solid $accent40,
  $border-radius: 25px,
  $color: $white,
  $className: 'btn-left, .btn-right',
  $hoverBg: $accent40,
  $hoverColor: $accent40
) {
  font-size: rem-calc(14);
  font-weight: bold;
  text-decoration: none;
}

.btn-left {
  float: left;
}

.btn-right {
  float: right;
}
@media print {
  .list-container {
    &__heading {
      font-size: 16pt !important;
    }
    .search-link-list {
      &__heading {
        font-size: 12pt !important;
      }
    }
  }
  .search-link-item__link {
    font-size: 12pt !important;
  }
}

blockquote {
  color: c('grey-600');
  margin: 0 0 rem-calc(30);
  padding: 0 rem-calc(10) rem-calc(20);
  position: relative;
  
  p {
    margin: 0;
    padding: 4px 10px;
    font-style: italic;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;

    @include breakpoint(large) {
      font-size: 24px;
      line-height: 36px;
    }

    @media print {
      background-color: transparent !important;
      background-image: none !important;
    }
  }

  &:before {
    content: '';
    display: block;
    background-image: url(../../images/quote.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;

    @include breakpoint(large) {
      width: 64px;
      height: 64px;
    }
  }

  @include breakpoint(large) {
    @if $editor == 'true' {
      margin: rem-calc(10) rem-calc(30) 0 0;
    } @else {
      margin: rem-calc(10) rem-calc(30) 0 -20%;
    }

    width: 65%;
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      text-align: left !important;
      width: 65% !important;
      float: left !important;
      margin: 0 0 30px !important;
    }
  }

  @include breakpoint(medium) {
    width: 65%;
  }

  @if $editor == 'true' {
    editmenuname: Citat;
  }
}
