@function formatUrl($iconColor) {
  @return '%23' + str-slice('#{$iconColor}', 2, -1);
}
@mixin SubjectIcon($paddingLeft: 10px, $iconColor: pink) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10'%3E%3Cg fill='none' fill='#{formatUrl($iconColor)}'  fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cpath d='M3.568 4.78L.251 1.463c-.335-.335-.335-.877 0-1.212.335-.335.877-.335 1.212 0l3.923 3.923c.335.335.335.878 0 1.212L1.463 9.31c-.335.335-.877.335-1.212 0-.335-.334-.335-.877 0-1.212L3.568 4.78z' transform='translate(-21 -127) translate(21 120) matrix(-1 0 0 1 6 7)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: $paddingLeft;
  @content;
}
