@import '../../platformui/Breadcrumb/styles.scss';

@include Breadcrumb($textColor: $neutral20, $itemPadding: 0 4px 0 0) {
  font-size: rem-calc(16);
  line-height: 1.5;

  @media print {
    display: none;
  }

  ol {
    padding-top: 6px;
  }

  a {
    @include focus-handler($neutral20, $offset: 2px);
    color: $accent40;
    text-shadow: 0 0 20px $white;
  }

  &__item {
    margin-right: 5px;
    &:after {
      margin-left: 10px;
    }
    a {
      color: $accent40;
    }
  }
  [aria-current] {
    color: inherit;
  }
}
