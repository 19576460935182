@import '../../platformui/Modal/tooltip-modal';

@include TooltipModal(
  $ctaColor: $accent40,
  $modalCloseBtnColor: $accent40,
  $modalPositionTop: 60px,
  $buttonBackgroundColor: $secondary90,
  $modalPanelLMaxWidth--large: 530px,
  $modalPanelHeight: auto,
  $useFadeOut: false,
  $openButtonArrowTop: 35px,
  $modalPanelBoxShadow: 0 0 10px 0 $neutral90
);

.tooltip-modal__modal-open {
  padding-right: 15px;
  svg {
    margin-left: rem-calc(15);
    width: rem-calc(15);
    height: rem-calc(9);
    transform: rotate(180deg);
  }
  &--open svg {
    transform: rotate(0);
  }
}
.tooltip-modal__modal-open--open:after {
  content: unset;
}

.tooltip-modal__modal-close {
  display: inline-flex;
  line-height: 30px;
  height: 30px;
  flex-direction: row-reverse;
  align-items: center;
  &:before {
    font-size: rem-calc(19);
    margin-left: 0.5rem;
  }
}

.tooltip-modal__modal-panel {
  border: 1px solid $neutral90;
}

.tooltip-modal__modal-panel:before {
  content: '';
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-top-color: $neutral90;
  border-left-color: $neutral90;
  width: 22px;
  height: 22px;
  background-color: white;
  box-shadow: 0 0 10px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -11px;
  right: 36px;
  box-shadow: -3px -3px 5px -1px $neutral90;
}

.tooltip-modal__modal-container {
  position: relative;
  width: auto;
  .c-formbutton {
    background-color: $accent40;
    font-weight: normal;
    &:active,
    &:hover,
    &:focus {
      background-color: $accent30;
    }
  }
}

.tooltip-modal__modal-open {
  min-height: 40px;
  font-size: 1rem;
}

.tooltip-modal__heading {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.tooltip-modal__header {
  height: auto;
  width: auto;
  position: static;
  border-bottom: 1px $neutral40;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 25px;
}
