@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNav(
  $className: 'c-metanav',
  $listBgColor: $white,
  $listItemColor: $neutral20,
  $fontSize: 1rem
) {
  @include Chevron(
    $className: 'chevron--mobile',
    $chevronColor: $primary40,
    $lineLength: 7px,
    $leftLinePosition: calc(100% - 24px),
    $rightLinePosition: calc(100% - 20px)
  ) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;

    outline-offset: -6px;
    outline-color: $primary40;
  }

  .c-metanav__list__item {
    box-shadow: inset 0 3px 4px 0 rgba($black, 0.2);

    a {
      @include focus-handler($color: $primary40, $offset: -2px);
      color: $primary40;
    }
  }
}

@include MobileNavSubLevel(
  $className: 'c-metanav',
  $hasBorder: false,
  $fontSize: 1rem,
  $textColor: $primary40
) {
  @include SubLevelIndent($className: c-metanav, $textIndent: 30px) {
    // sublevel 2
    @include SubLevelIndent($className: c-metanav, $textIndent: 60px) {
      // sublevel 3
      @include SubLevelIndent($className: c-metanav, $textIndent: 80px)
        // sublevel 4;;;;;;;;;;;;;;;;;
;
    }
  }
}
