.c-event-article-list {
  margin-top: 20px;
  border-bottom: solid 5px transparent;
  flex-basis: 100%;

  &:hover {
    border-bottom: solid 5px $accent30;
    cursor: pointer;

    a {
      text-decoration: underline;
      color: $accent30;
    }

    &__text-container {
      h3 {
        a {
          &:hover {
            color: $accent30;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__content-wrapper {
    display: flex;
    column-gap: 20px;
    border-bottom: 1px solid c('dhusar-grey');
    min-height: 130px;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }
  }

  &__date-container {
    @include breakpoint(large) {
      background-color: $white;
      box-shadow: 0px 2px 6px 0px rgba(c('black'), 0.3);
      border-radius: 3px;
      max-height: 100px;
      max-width: 66px;
    }
  }

  &__text-container {
    padding-bottom: 20px;

    &__text {
      margin-top: 8px;
    }

    h3 {
      margin: 0 !important;
      display: flex;
      padding: 4px 0;

      @include breakpoint(large) {
        padding: 0;
      }

      a {
        text-decoration: none;

        &:before {
          content: url('../../framework/images/short-arrow-right.svg');
          background-repeat: no-repeat;
          margin-right: 4px;
          height: 20px;
          float: left;
        }
      }
    }

    p,a {
      font-size: inherit;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 8px !important;
      word-break: break-word;
    }
  }
}

.c-date-card {
  &__container {
    display: none;
    
    @include breakpoint(large) {
      display: flex;
      padding: 17px 12px;
      justify-items: center;
      flex-direction: column;
      height: 100px;
      width: 66px;

      & > div {
        display: flex;
        margin: auto;
      }
    }
  }

  &__month,
  &__year {
    margin-right: 5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;

    &__long {
      margin-right: 5px;
      text-transform: capitalize;
    }
  }

  &__day {
    font-family: Poppins;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: $accent40;
    margin-right: 10px;
  }

  &__month {
    &__short {
      display: none;
    }
  }

  @include breakpoint(large) {
    &__day {
      font-size: 2.188rem;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -1.75px;
      text-align: center;
    }

    &__month {
      &__short {
        display: block !important;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: $neutral20;
        text-transform: uppercase;
      }

      &__long {
        display: none !important;
      }
    }

    &__year {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: $neutral20;
    }

    &__invalid {
      margin: 5px;
    }
  }
}

.event-details {
  &__date,
  &__time {
    display: block;
  }

  &__subtext {
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    padding-right: 6px;
  }

  &__info-container > div {
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .show-on-mobile {
    display: block;
  }
  
  @include breakpoint(large) {
    .show-on-mobile {
      display: none;
    }
  }
}
