@mixin WithSectionWrapper() {
  .list-container--has-section-wrapper & {
    @include breakpoint(large) {
      @content;
    }
  }
}

@mixin SearchLinkList($borderColor: purple, $headingColor: salmon) {
  .search-link-list {
    border-top: 1px solid $borderColor;
    padding: 20px 0;

    &__btn {
      // @include focus-handler($focusColor);

      background: none;
      cursor: pointer;
      padding: 0;
      position: relative;
      text-align: left;
      width: 100%;

      [dir='rtl'] & {
        text-align: right;
      }
    }

    &__content {
      display: none;

      &--is-expanded {
        display: block;
      }
    }

    &__heading {
      color: $headingColor;
      display: block;
      font-size: rem-calc(14);
      letter-spacing: 1.4px;
      line-height: 20px;
      margin: 0 40px 0 10px;
      text-transform: uppercase;

      [dir='rtl'] & {
        margin: 0 10px 0 40px;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(20);
        letter-spacing: normal;
        line-height: 24px;
      }
    }

    .c-pagination {
      .c-spinner {
        position: absolute;
        display: block;
        margin: 0 auto;
        width: 70px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
      }
      &--is-loading {
        .c-pagination__text {
          opacity: 0;
        }
        .c-spinner {
          opacity: 1;
        }
      }
    }

    @content;
  }
}
