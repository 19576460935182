@mixin ListContainer($headingColor: orange) {
  .list-container {
    margin-bottom: 60px;
    position: relative;
    clear: both;

    &__heading {
      color: $headingColor;
      font-size: rem-calc(22);
      margin: 0 0 20px 0;

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        margin: 0 0 30px 0;
      }
    }
  }

  @content;
}
