@import '../../platformui/Mobilenav/expand-button/styles';

@include ExpandButton(
  $className: 'plus--subheading',
  $topPosition: unset,
  $bgColorBtn: $accent40,
  $btnHeight: 30px,
  $btnWidth: 30px,
  $btnBorderRadius: 100%,
  $plusMinusColor: $white
) {
  position: relative;
  right: 6px;
  display: none;
  @include breakpoint(large) {
    display: block;
  }

  &--is-open {
    &:before {
      transform: rotate(90deg);
    }
  }

  @media print {
    display: none;
  }
}
