@import '../../platformui/Alert/styles.scss';

@include Alert(
  $borderRadius: 10px,
  $borderStyle: 1px solid,
  $infoBorderColor: $neutral40,
  $errorBorderColor: $error40,
  $errorBackgroundColor: $error99,
  $warningIcon: '../../framework/images/alert.svg',
  $warningBorderColor: $neutral40,
  $warningBackgroundColor: $attention95,
  $successBorderColor: $success40,
  // PlatformUI component require these to be set to existing images. If used in future, add correct images and update here.
  $checkIcon: '../../framework/images/alert.svg',
  $infoIcon:
    "data:image/svg+xml,%3csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 28.5C22.4558 28.5 28.5 22.4558 28.5 15C28.5 7.54416 22.4558 1.5 15 1.5C7.54416 1.5 1.5 7.54416 1.5 15C1.5 22.4558 7.54416 28.5 15 28.5ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z' fill='%236F5E4D'/%3e%3cpath d='M14.5901 9C15.0039 9 15.3561 8.85222 15.6467 8.55665C15.9374 8.26108 16.0827 7.9064 16.0827 7.49261C16.0827 7.07882 15.9374 6.7266 15.6467 6.43596C15.3561 6.14532 15.0039 6 14.5901 6C14.1664 6 13.8093 6.14532 13.5187 6.43596C13.228 6.7266 13.0827 7.07882 13.0827 7.49261C13.0827 7.9064 13.2305 8.26108 13.5261 8.55665C13.8216 8.85222 14.1763 9 14.5901 9Z' fill='%236F5E4D'/%3e%3cpath d='M17.755 22.4971C17.8903 22.4971 18 22.3874 18 22.2521C18 22.1229 17.8998 22.0158 17.7712 22.0042C17.4766 21.9777 16.9853 21.9223 16.8402 21.8379C16.6286 21.7148 16.4687 21.5281 16.3607 21.2776C16.2528 21.0271 16.1988 20.5239 16.1988 19.7681L16.1513 11.9925C16.1488 11.5801 15.8137 11.2471 15.4013 11.2471H12.3845C12.1722 11.2471 12 11.4192 12 11.6316C12 11.844 12.1722 12.0161 12.3845 12.0161H13.2283C13.6236 12.0161 13.9507 12.3223 13.9714 12.7171C14.0051 13.3579 14.0476 14.3234 14.0476 15.1143V19.7681C14.0476 20.5151 13.9893 21.0205 13.8727 21.2842C13.7561 21.5479 13.5984 21.7368 13.3997 21.8511C13.263 21.9297 12.7622 21.9813 12.4631 22.0058C12.3342 22.0164 12.2334 22.1234 12.2334 22.2528C12.2334 22.3877 12.3428 22.4971 12.4777 22.4971H17.755Z' fill='%236F5E4D'/%3e%3c/svg%3e",
  $titleColor: $neutral40
) {
  .c-alert {
    align-items: unset;
    padding: 24px 0;
    margin-top: 40px;
    opacity: 1;
    transition: opacity 0.3s ease;
    box-shadow: none;
    @include focus-handler();

    &__icon {
      background-size: 23px;
      height: 23px;
      margin: 0;
      width: 23px;

      @include breakpoint(large) {
        margin: 0 24px;
        background-size: 59px;
        height: 59px;
        width: 59px;
      }
    }

    &__inner {
      padding-left: 0;
      @include breakpoint(small only) {
        display: block;
        margin-left: 20px;
        margin-right: 20px;
      }

      p {
        text-indent: 0px;
        margin: 0;
      }
    }

    &__icon-container {
      align-items: unset;
      left: 0;
      position: relative;
      top: 2px;
      @include breakpoint(small only) {
        float: left;
        margin-left: 0px;
      }

      @include breakpoint(medium) {
        top: 5px;
        margin: 0px 12px 0 12px;
      }

      @include breakpoint(large) {
        align-items: center;
        left: unset;
        top: unset;
        margin: 0;

        .contact-alert & {
          position: static;
        }
      }
    }

    p {
      font-size: rem-calc(18);
      margin-right: 20px;
      padding: 0;
    }

    @include breakpoint(large) {
      align-items: center;
    }
    .contact-alert & {
      margin-bottom: 20px;
      &__icon-container {
        top: 12px;

        @include breakpoint(medium) {
          top: 5px;
        }

        @include breakpoint(large) {
          margin: 0;
        }
      }
    }

    &--small {
      p {
        font-size: rem-calc(16);
      }
    }

    .is-loading & {
      opacity: 0.5;
    }
  }
}
