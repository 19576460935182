@mixin ReadingAid(
  $backgroundColor: transparent,
  $activateButtonBg: pink,
  $bottomBorderRadius: false,
  $balloon-tip: false,
  $padding: (
    small: 20px,
    large: 25px 20px,
  ),
  $maxWidth: 1340px
) {
  .c-reading-aid {
    background-color: $backgroundColor;
    top: 0;
    left: 0;
    margin: 0 auto;
    max-width: $maxWidth;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all 0.4s ease;
    transform: translateY(-100%);
    visibility: hidden;
    width: 100%;
    z-index: 11;

    &__toggle {
      height: 100%;
      position: relative;
      width: 100%;
    }

    @if $bottomBorderRadius == true {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;

      @include breakpoint(large) {
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }

    @if $balloon-tip == true {
      &:before {
        position: absolute;
        content: '';
        height: 66px;
        width: 75px;
        right: 110px;
        bottom: -32px;
        background-size: 100%;
        transform: rotateY(180deg) scale(0.5);
        background: url('../../framework/images/balloon-tip.png') no-repeat;
        transition: all 0.3s ease-in-out;

        @include breakpoint(medium) {
          right: 150px;
          transform: rotateY(180deg) scale(0.7);
        }

        @include breakpoint(large) {
          right: 40%;
          transform: rotateY(180deg) scale(1);
        }
      }
    }

    &--is-opened,
    &:target {
      filter: drop-shadow(0 4px 6px rgba($black, 0.3));
      opacity: 1;
      visibility: visible;
    }

    &__inner {
      position: relative;
      padding: map-get($padding, small);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint(large) {
        padding: map-get($padding, large);
        flex-direction: row;
      }

      p {
        margin-bottom: 20px;
        width: 280px;
        text-align: center;

        @include breakpoint(medium) {
          width: 400px;
        }

        @include breakpoint(large) {
          text-align: left;
          margin-bottom: 0;
          width: 100%;
        }
      }

      > div {
        @include breakpoint(large) {
          width: 75%;
        }
      }

      &__loaded {
        animation: fadeIn;
        animation-duration: 0.5s;
      }
    }

    .c-forms__button {
      background-color: $activateButtonBg;
      color: $white;

      &:hover,
      &:focus {
        background-color: darken($activateButtonBg, 4%);
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @content;
  }
}

//----- HIDES LINK AT THE BOTTOM OF THE DOM
a#_ba__link {
  display: none;
}
