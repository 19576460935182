.c-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;

  &__text {
    color: $white;
  }

  .c-spinner {
    span {
      background-color: $neutral40;
    }
  }
}
