@import '../../platformui/Footer/styles.scss';
@import './footer-linklist.scss';
@import '../../platformui/Framework/platform-mixins/CookieProSettings.scss';

@include Footer(
  $color: $white,
  $padding: (
    65px 0 50px,
    40px 0 100px,
    70px 0 90px,
  ),
  $logoSiteFooterRight: false,
  $logoSiteFooterTop: false
) {
  background-color: $primary40;
  margin-top: 30px;
  box-shadow: none;

  &__content {
    background-color: $primary40;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEzIiBoZWlnaHQ9IjU3IiB2aWV3Qm94PSIwIDAgMTEzIDU3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTEuOTQwN2UtMDUgMEM2LjQ2OTg0ZS0wNiAwLjAxNTc3OCAwIDAuMDMxNTU3NCAwIDAuMDQ3MzM4NEMwIDMxLjI1MTUgMjUuMjk1OSA1Ni41NDczIDU2LjUgNTYuNTQ3M0M4Ny43MDQxIDU2LjU0NzMgMTEzIDMxLjI1MTUgMTEzIDAuMDQ3MzM4NEMxMTMgMC4wMzE1NTc0IDExMyAwLjAxNTc3OCAxMTMgMEg3Ny40NTk2Qzc3LjQ1OTcgMC4wMTU3NzU0IDc3LjQ1OTcgMC4wMzE1NTQ5IDc3LjQ1OTcgMC4wNDczMzg0Qzc3LjQ1OTcgMTEuNjIzMSA2OC4wNzU4IDIxLjAwNyA1Ni41IDIxLjAwN0M0NC45MjQyIDIxLjAwNyAzNS41NDAzIDExLjYyMzEgMzUuNTQwMyAwLjA0NzMzODRDMzUuNTQwMyAwLjAzMTU1NDkgMzUuNTQwMyAwLjAxNTc3NTQgMzUuNTQwNCAwSDEuOTQwN2UtMDVaIiBmaWxsPSIjQzAzRjczIi8+DQo8L3N2Zz4=');
    background-position: left 65vw top;
    background-repeat: no-repeat;

    @include breakpoint(large) {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk0IiBoZWlnaHQ9Ijk3IiB2aWV3Qm94PSIwIDAgMTU0IDc3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTAgMEMwIDQyLjUyNiAzNC40NzQgNzcgNzcgNzdDMTE5LjUyNiA3NyAxNTQgNDIuNTI2IDE1NCAwSDEwNS41NjRDMTA1LjU2NCAxNS43NzU4IDkyLjc3NTkgMjguNTY0NSA3NyAyOC41NjQ1QzYxLjIyNDEgMjguNTY0NSA0OC40MzU1IDE1Ljc3NTggNDguNDM1NSAwSDBaIiBmaWxsPSIjQzAzRjczIi8+DQo8L3N2Zz4='),
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjcxIiBoZWlnaHQ9IjEzNSIgdmlld0JveD0iMCAwIDIzMSAxMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMCAxMTVDMC4yNjk0MDkgNTEuNDQxIDUxLjg3NjcgMCAxMTUuNDk5IDBDMTc5LjEyMSAwIDIzMC43MjggNTEuNDQxIDIzMC45OTggMTE1SDE1OC4zNDNDMTU4LjA3NSA5MS41NjY2IDEzOC45OTYgNzIuNjUzMiAxMTUuNDk5IDcyLjY1MzJDOTIuMDAyIDcyLjY1MzIgNzIuOTIyOSA5MS41NjY2IDcyLjY1NDkgMTE1SDBaIiBmaWxsPSIjQzAzRjczIi8+DQo8L3N2Zz4=');
      min-height: 330px;
      background-position: left calc(48vw - 300px) top,
        left calc(48vw - 240px) bottom;
    }

    &__editor {
      @include breakpoint(large) {
        margin-bottom: 50px;
      }

      p,
      a {
        font-size: 1rem;
        color: $white;
      }
    }

    &__inner {
      margin-bottom: 20px;
    }
  }

  &__heading,
  &__subheading {
    font-size: rem-calc(26);
    @include breakpoint(large) {
      font-size: rem-calc(28);
    }
    font-weight: bold;
    color: $white;
    display: inline;
    line-height: 1.14;
    letter-spacing: normal;
  }

  &__logo {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;

    &__text {
      margin-bottom: 0;
      p,
      a {
        font-size: 0.875rem;
        color: $white;
      }
    }
  }

  &__site {
    background-color: $primary30;
    box-shadow: none;

    @include CookieProSettings(
      $white,
      $fontSize: rem-calc(14),
      $focusColor: $white
    );

    &__inner {
      flex-direction: column;
      &__bottom-links {
        @include breakpoint(medium) {
          text-align: center;
          max-width: none;
          padding-top: 0;
        }
      }
    }

    a {
      @include focus-handler($white);
      color: $white;
    }
  }

  &__error-logo {
    height: 56px;
    margin: 40px auto 50px;
    width: 150px;
    display: block;
    object-fit: contain;
  }

  &__collapsible-lists {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU0IiBoZWlnaHQ9Ijc4IiB2aWV3Qm94PSIwIDAgMTU0IDc4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTE1NCA3Ny41NDczQzE1NCA3Ny41MjU5IDE1NCA3Ny41MDQ0IDE1NCA3Ny40ODI5QzE1NCAzNC45OTI1IDExOS41MjYgMC41NDczMzYgNzcgMC41NDczMzlDMzQuNDc0IDAuNTQ3MzQzIC0zLjcyMDI2ZS0wNiAzNC45OTI1IC01LjYzNTMxZS0wOSA3Ny40ODI5Qy0zLjc1NjdlLTA5IDc3LjUwNDQgMS41MjU2OWUtMDUgNzcuNTI1OSAzLjA1MTc2ZS0wNSA3Ny41NDc0TDQ4LjQzNTYgNzcuNTQ3NEM0OC40MzU1IDc3LjUyNTkgNDguNDM1NSA3Ny41MDQ0IDQ4LjQzNTUgNzcuNDgyOUM0OC40MzU1IDYxLjcyMDIgNjEuMjI0MSA0OC45NDIzIDc3IDQ4Ljk0MjNDOTIuNzc1OCA0OC45NDIzIDEwNS41NjUgNjEuNzIwMiAxMDUuNTY1IDc3LjQ4MjlDMTA1LjU2NSA3Ny41MDQ0IDEwNS41NjQgNzcuNTI1OSAxMDUuNTY0IDc3LjU0NzRMMTU0IDc3LjU0NzNaIiBmaWxsPSIjQzAzRjczIi8+DQo8L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: left 20px bottom;
    background-color: $primary40;
    padding-top: 15px;
    padding-bottom: 100px;
  }

  .c-collapsible-panel {
    background-color: $primary40;
    @include breakpoint(small) {
      max-width: calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(medium) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    &__panel {
      background: $primary40;
    }

    &__button {
      box-shadow: none;
      background-color: $primary40;
      &__inner {
        border-bottom: 1px solid $white;
        padding: 7px 0;
        @include breakpoint(medium) {
          width: 100%;
        }
      }
    }
  }
}
