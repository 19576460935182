@mixin AnchorNavHorizontal(
  $backgroundColor: $white,
  $borderColor: #3b4266,
  $borderWidth: 0.3px,
  $fontFamily: $body-font-family,
  $fontColor: #333,
  $headingFontColor: #333,
  $fontSize: rem-calc(18),
  $fontWeight: 400,
  $mobileExpandButtonBg: #eef7fb,
  $mobileExpandButtonBorderBg: #000,
  $mobileExpandButtonIcon: '',
  $mobileExpandButtonIconColor: $white,
  $mobileExpandButtonIconBg: #333,
  $mobileExpandButtonExpandedMenuBg: #f1f2f2,
  $mobileHeadingFont: $body-font-family,
  $activeLinkIconBg: #000,
  $activeLinkIconColor: $white,
  $linkArrowColor: #444,
  $linkArrowBg: $white,
  $borderRadius: 0px,
  $iconFontSize: 10px,
  $iconRotate: scaleY(-1),
  $iconPaddingRight: 20,
  $fontWeightHeader: normal,
  $marginSmallerScreens: 0px,
  $smallerScreensWidth: auto,
  $smallerScreensBorderWidth: 0px,
  $smallerScreensBorderRadius: 0px,
  $transitionsType: all 0.3s ease,
  $transformOrigin: center center,
  $fixedNavBGColor: $white,
  $textFillColor: transparent,
  $paddingLink: rem-calc(20),
  $minWidthLink: rem-calc(280),
  $marginBlock: rem-calc(80)
) {
  .horizontal-anchor-nav {
    margin-block: $marginBlock;

    @include breakpoint(large) {
      padding-inline: rem-calc(70);
    }
    &__list {
      padding-left: 0;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      gap: rem-calc(10);
      list-style: none;

      @include breakpoint(large) {
        flex-flow: row wrap;
        margin: 0;
      }

      @include breakpoint(small) {
        margin: $marginSmallerScreens;
      }

      li {
        margin: 0;
      }

      &__link {
        display: block;
        width: 100%;
        padding: $paddingLink;
        background: $backgroundColor;
        font-family: $fontFamily;
        color: $fontColor;
        font-size: $fontSize;
        font-weight: $fontWeight;
        text-decoration: auto;

        span {
          white-space: normal;

          @include link-arrow(
            $rotate: 90deg,
            $top: -1px,
            $left: 15px,
            $paddingLeft: 45px
          );
          @include breakpoint(large) {
            @include link-arrow(
              $rotate: 90deg,
              $top: -2px,
              $left: 0,
              $paddingLeft: 30px
            );
          }
        }

        &:hover {
          color: $fontColor;
          span {
            &:before {
              background: $activeLinkIconBg !important;
              -webkit-background-clip: text !important;
              -webkit-text-fill-color: $textFillColor !important;
            }
            &:after {
              background: $activeLinkIconColor !important;
            }
          }
        }

        @include breakpoint(large) {
          min-width: $minWidthLink;
          border: $borderWidth solid $borderColor;
          border-radius: $borderRadius;
        }

        @include breakpoint(small) {
          min-width: $smallerScreensWidth;
          border: $smallerScreensBorderWidth solid $borderColor;
          border-radius: $smallerScreensBorderRadius;
        }
      }
    }
    &__navigation {
      &--is-fixed {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 90000;
        border-bottom: 1px solid $borderColor;

        @include breakpoint(large) {
          background: $backgroundColor;
        }

        .horizontal-anchor-nav__list {
          gap: 0;
          max-height: 0;
          opacity: 0;
          display: none;
          transition: none;

          li {
            text-align: center;
          }

          &--is-expanded {
            margin: 0;
            padding-left: 0;
            max-height: 1000px;
            opacity: 1;
            display: block;
            transition: all 0.3s ease;
            border-top: 1px solid $borderColor;
            background-color: $fixedNavBGColor;

            li {
              margin: 0;
              &:last-child a {
                border-bottom: none;
              }
            }
          }
          @include breakpoint(large) {
            gap: 0;
            max-height: 1000px;
            opacity: 1;
            display: flex;
          }
        }
        .horizontal-anchor-nav__list__link {
          border: none;
          border-bottom: 1px solid $borderColor;
          background: #fff;
          font-size: rem-calc(16);
          background-color: $mobileExpandButtonExpandedMenuBg;
          @include breakpoint(large) {
            border-bottom: none;
            background: transparent;
          }

          span {
            position: relative;
            @include link-arrow(
              $rotate: 90deg,
              $top: -1px,
              $left: 18px,
              $paddingLeft: 45px,
              $color: $linkArrowColor,
              $bgColor: $linkArrowBg,
              $fontSize: rem-calc(17),
              $bgSize: rem-calc(16)
            );
          }

          &--is-selected {
            font-weight: 600;
            span {
              &:before {
                background: $activeLinkIconBg !important;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: $textFillColor !important;
              }
              &:after {
                background: $activeLinkIconColor !important;
              }
            }
          }
        }

        .horizontal-anchor-nav__mobile-expand {
          display: flex;
          cursor: pointer;
          font-weight: $fontWeightHeader;
          @include breakpoint(large) {
            display: none;
          }
        }
      }
    }

    &__mobile-expand {
      display: none;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      height: rem-calc(55);
      padding-inline: rem-calc(40);
      padding-right: rem-calc($iconPaddingRight);
      background-color: $mobileExpandButtonBg;
      font-family: $mobileHeadingFont;
      color: $headingFontColor;
      font-size: rem-calc(16);
      font-weight: $fontWeight;
      text-align: left;

      .icon {
        @include icon($mobileExpandButtonIcon);
        position: relative;
        display: block;
        background-color: $mobileExpandButtonIconBg;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        transition: $transitionsType;

        &:before {
          color: $mobileExpandButtonIconColor;
          position: absolute;
          top: 8px;
          right: 7px;
          font-size: $iconFontSize;
          z-index: 99;
          line-height: 12px;
        }
        &.expanded {
          transform-origin: $transformOrigin;
          transform: $iconRotate;
        }
      }

      &:before {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: rem-calc(8);
        height: rem-calc(55);
        background: $mobileExpandButtonBorderBg;
      }
    }

    @media print {
      display: none;
    }
    @content;
  }
}
