@import '../../platformui/HighLight/styles';

@include HighLight($margin: (0,
    0,
  ),
  $padding: (20px 20px 30px,
    30px 40px 30px,
  )) {
  background-color: $secondary95;
  border-radius: 3px;

  &--noicon {
    margin-bottom: 20px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: normal;
    color: $neutral20;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 1px solid $accent40;
    font-size: 22px;
    font-weight: 600;
  }

  @media (max-width: 1220px) {

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
    }
  }

  ol,
  ul {
    li {
      color: $neutral20;

      &:before {
        color: $neutral20;
      }
    }
  }

  &__header {
    max-width: rem-calc(720);
  }

  &__text-container {
    max-width: rem-calc(720);
    overflow-wrap: break-word;
  }

  &__cta-container {
    &__cta-primary-button {
      @include LinkButton($bgColor: $accent40,
        $hoverBgColor: $accent30,
        $border: 1px solid $accent40,
        $border-radius: rem-calc(3),
        $color: $white,
        $hoverColor: $white);
      text-transform: uppercase;

      a {
        padding: 12px 40px 12px 40px;
      }
    }

    &__cta-secondary-button {
      @include LinkButton($bgColor: $white,
        $hoverBgColor: $accent30,
        $border: 1px solid $accent40,
        $border-radius: rem-calc(3),
        $color: $accent40,
        $hoverColor: $white);
      text-transform: uppercase;

      a {
        padding: 12px 40px 12px 40px;
      }
    }
  }
}
