@import '../../platformui/Meganav/styles.scss';

@import './navigationpush.scss';

@include Meganav(
  $width: $global-width,
  $bgColor: $secondary95,
  $boxShadow: 0px 3px 3px 0px rgba($black, 0.2),
  $columnGap: (
    20px,
    30px,
    40px,
  ),
  $itemContainerPadding: 3px 0,
  $itemPadding: 5px 10px 5px 25px
) {
  padding: 60px 0 60px;

  background-size: 800px 774px;

  &__list {
    &__item {
      border-bottom: solid 1px rgba($neutral40, 0.3);

      a {
        color: $primary40;
        text-decoration: none;

        @include focus-handler($offset: 1px);

        @include link-arrow(
          $color: $primary40,
          $fontSize: 8px,
          $bgSize: 20px,
          $bgTop: 10px,
          $useHoverCircle: false,
          $top: 14px,
          $hoverColor: $primary30,
          $iconContent: 'e835'
        );
      }
    }
  }

  &__container {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }
}
