html {
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0px;
  padding: 0px;
}

ul,
ol,
dl {
  list-style: none;
}

body.no-scroll {
  overflow: hidden;
}

body {
  @include base-font-family();
  background-color: c('white');
  line-height: 1.64;
  color: c('neutral20');
  font-size: 100%;
  margin: 0;
  padding: 0;
}

.u-flexwrap,
.u-columns {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.u-article-main,
.u-decisionbasis-main {
  display: flex;

  .u-right-col {
    margin-left: auto;
    padding-top: 50px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }

    @media print {
      display: none;
    }
  }
}

// specific print style for fucking Firefox
@supports (-moz-appearance: none) {
  @media print {
    .u-article-main {
      display: block !important;
    }
  }
}

//Printfix for ordered lists in firefox
@media print {
  .u-article-main {
    ol {
      counter-reset: revert;
    }
  }
}

.u-100-height {
  display: none;
  flex-flow: column;
  height: 100%;
  position: relative;
  width: 100%;

  @include breakpoint(large) {
    display: flex;
  }
}

.u-search-page {
  margin-top: 25px;
}

.u-text-center {
  text-align: center;
}

.main-wrapper {
  min-height: 100vh;
  position: relative;

  @media print {
    margin: 40px auto 0;
    max-width: 52rem;
    margin-left: unset;
    margin-right: unset;
  }
}

.main {
  display: block;
}

.row {
  @media print {
    margin: 0 !important;
  }
}

@media print {
  * {
    overflow: visible !important;
  }

  body {
    background-color: transparent;
    font-size: rem-calc(10);
  }

  @page :first {
    margin: 1cm 17% 2cm 17%;
  }

  @page {
    margin: 2cm 17%;
  }
}

[type='button'] {
  line-height: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: 100%;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  user-select: none;
  cursor: pointer;
}

h1,
h1-small,
h2,
h3,
h4,
h5,
h6,
caption {
  text-rendering: optimizeLegibility;
  overflow-wrap: anywhere;
}

a,
button {
  @include focus-handler();
}
