@mixin Share(
  $componentMargin: 45px 0 0 0,
  $componentPadding: 14px 0,
  $componentBorder: solid 1px rgba($black, 0.2),
  $componentItemMarginLeft: 10px,
  $iconBgColor: #fff,
  $iconBgHoverColor: #fff,
  $iconColor: #fff,
  $iconSocialColor: #fff,
  $headingTextTransform: none
) {
  .c-share {
    @include clearfix();
    border-bottom: $componentBorder;
    border-top: $componentBorder;
    clear: both;
    float: left;
    margin: $componentMargin;
    padding: $componentPadding;
    width: 100%;

    &__list {
      float: right;
      list-style: none;
      height: 40px;
      margin: 0;
      padding: 0;

      [dir='rtl'] & {
        float: left;
      }

      &__item {
        display: inline-block;
        margin: 0 0 0 $componentItemMarginLeft;
        height: 40px;
        width: 40px;

        [dir='rtl'] & {
          margin: 0 $componentItemMarginLeft 0 0;
        }

        button {
          display: inline-block;
          height: 40px;
          width: 40px;
          background: none;
          cursor: pointer;
        }
      }
    }

    &__heading {
      text-transform: $headingTextTransform;
      float: left;
      font-weight: normal;
      margin: 0;
      padding: 8px 0;

      [dir='rtl'] & {
        float: right;
      }
    }

    &__icon {
      background: $iconBgColor;
      border-radius: 50%;
      color: $iconColor;
      display: inline-block;
      height: 40px;
      position: relative;
      width: 40px;
      font-size: rem-calc(40);

      &:before {
        left: 0px;
        position: absolute;
        top: 0px;
      }

      &--copy-link {
        background-image: url('../../framework/images/copy-link.svg');
        background-color: $iconSocialColor;
        background-repeat: no-repeat;
        background-position: center;

        &:before {
          [dir='rtl'] & {
            margin-left: 0;
          }
        }
        &:hover {
          background-color: $iconBgHoverColor;
        }
      }

      &--mailto {
        @include icon('f0e0');
        background-color: $iconSocialColor;

        &:before {
          [dir='rtl'] & {
            margin-left: 0;
          }
        }
        &:hover {
          background: $iconBgHoverColor;
        }
      }

      &--print {
        @include icon('e80d');
        background-color: $iconSocialColor;

        &:before {
          [dir='rtl'] & {
            margin-left: 0;
          }
        }
        &:hover {
          background: $iconBgHoverColor;
        }
      }
    }

    @media print {
      display: none;
    }

    @content;
  }
}
