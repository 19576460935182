@import '../../platformui/AlertList/styles.scss';
@import '../../platformui/StatusMessage/styles.scss';

@include AlertList() {
  .c-alertlist {
    @include StatusMessage(
      $bgColor: $attention95,
      $icon: '../../framework/images/alert.svg'
    );

    h2 {
      color: inherit;
    }
  }
}
