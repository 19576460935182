@use 'sass:math';

$button-size-medium: 30px;

@mixin Burger(
  $burger-color: red,
  $burger-size: 21px,
  $lines-top: (
    -4px,
    -4px,
  ),
  $before-top: (
    8px,
    8px,
  ),
  $after-top: (
    -8px,
    -8px,
  ),
  $position-right: 20px,
  $position-left: 17px,
  $position-top: (
    3px,
    3px,
  ),
  $lines-height: (
    2px,
    4px,
  )
) {
  // =========== General button styles & variables =======================================//
  .c-header {
    &__burger {
      border: none;
      cursor: pointer;
      position: absolute;
      right: $position-right;
      top: nth($position-top, 1);
      z-index: 2;

      &__text {
        font-size: 1rem;
        margin-right: 10px;
      }

      [dir='rtl'] & {
        left: $position-left;
        right: auto;
      }

      @include breakpoint(medium) {
        top: nth($position-top, 2);
      }

      @include breakpoint(large) {
        display: none;
      }
    }
  }

  $color: $burger-color;

  // increase this to see the transformations happen in slow motion

  /* ====================== lines button ==================================================*/

  //common to all three-liners
  .lines-button {
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: relative;
    top: nth($lines-top, 1);

    @include breakpoint(medium) {
      top: nth($lines-top, 2);
    }

    &:active {
      transition: 0s;
    }
  }

  //common to all three-liners
  .lines {
    @include line(
      $burger-size: $burger-size,
      $lines-height: $lines-height,
      $color: $burger-color
    );
    position: relative;

    &:before,
    &:after {
      @include line(
        $burger-size: $burger-size,
        $lines-height: $lines-height,
        $color: $burger-color
      );

      position: absolute;
      left: 0;
      content: '';
      -webkit-transform-origin: math.div($burger-size, 14) center;
      transform-origin: math.div($burger-size, 14) center;

      @include breakpoint(medium) {
        -webkit-transform-origin: math.div($button-size-medium, 14) center;
        transform-origin: math.div($button-size-medium, 14) center;
      }
    }
    &:before {
      top: nth($before-top, 1);
      @include breakpoint(medium) {
        top: nth($before-top, 2);
      }
    }
    &:after {
      top: nth($after-top, 1);
      @include breakpoint(medium) {
        top: nth($after-top, 2);
      }
    }
  }

  .lines-button.close {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
  // for both the arrow up and left icons
  .lines-button.arrow.close {
    .lines {
      &:before,
      &:after {
        top: 0;
        width: math.div($burger-size, 1.8);

        @include breakpoint(medium) {
          width: math.div($button-size-medium, 1.8);
        }
      }
      &:before {
        -ms-transform: rotate(40deg);
        -webkit-transform: rotate3d(0, 0, 1, 40deg);
        transform: rotate3d(0, 0, 1, 40deg);
      }
      &:after {
        -ms-transform: rotate(-40deg);
        -webkit-transform: rotate3d(0, 0, 1, -40deg);
        transform: rotate3d(0, 0, 1, -40deg);
      }
    }
  }

  //arrow up only: just rotate by 90degrees
  .lines-button.arrow-up.close {
    -webkit-transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
    transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, 90deg);
  }
  //three-lines to minus only
  .lines-button.minus.close {
    .lines {
      &:before,
      &:after {
        -webkit-transform: none;
        transform: none;
        top: 0;
        width: $burger-size;

        @include breakpoint(medium) {
          width: $button-size-medium;
        }
      }
    }
  }

  //three-lines to x
  .lines-button.x.close {
    .lines {
      background: transparent;
      &:before,
      &:after {
        -ms-transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        top: 0;
        width: $burger-size;

        @include breakpoint(medium) {
          width: $button-size-medium;
        }
      }
      &:before {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate3d(0, 0, 1, 45deg);
        transform: rotate3d(0, 0, 1, 45deg);
      }
      &:after {
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate3d(0, 0, 1, -45deg);
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
  /**** Grid  ****/

  @content;
}

//common to all three-liners
@mixin line(
  $burger-size: 21px,
  $lines-height: (
    2px,
    4px,
  ),
  $transition: 0.3s,
  $color: blue
) {
  display: inline-block;
  width: $burger-size;
  border-radius: 2px;
  transition: $transition;
  $button-size-medium: $button-size-medium;

  height: nth($lines-height, 1);
  background: $color;

  @include breakpoint(medium) {
    height: nth($lines-height, 2);
    width: $button-size-medium;
  }
}
