@import '../../platformui/AnchorNavHorizontal/styles';

@mixin link-arrow(
  $color: $accent30,
  $hoverColor: $accent40,
  $top: 16px,
  $left: 2px,
  $fontSize: rem-calc(25),
  $bgSize: rem-calc(18),
  $bgTop: $top - 4px,
  $paddingLeft: 25px,
  $useHoverCircle: false,
  $iconContent: 'e820',
  $rotate: 0deg,
  $bgColor: transparent
) {
  @include icon($iconContent);
}

@include AnchorNavHorizontal(
  $backgroundColor: $white,
  $fontFamily: $base-font,
  $mobileExpandButtonBorderBg: $primary40,
  $mobileExpandButtonIcon: 'f107',
  $mobileExpandButtonIconColor: $accent40,
  $mobileExpandButtonIconBg: $white,
  $mobileHeadingFont: $secondary-font,
  $activeLinkIconBg: $accent40,
  $activeLinkIconColor: $white,
  $linkArrowColor: $accent40,
  $linkArrowBg: $white,
  $borderColor: $neutral40,
  $borderWidth: 1px,
  $fontColor: $accent40,
  $headingFontColor: $black,
  $fontSize: rem-calc(18),
  $fontWeight: 400,
  $mobileExpandButtonBg: $white,
  $borderRadius: 3px,
  $iconFontSize: 30px,
  $iconPaddingRight: 17,
  $fontWeightHeader: bold,
  $marginSmallerScreens: 15px,
  $smallerScreensWidth: auto,
  $smallerScreensBorderWidth: 0.3px solid $neutral40,
  $smallerScreensBorderRadius: 0px,
  $transitionsType: all 0.1s ease,
  $transformOrigin: 0% 50%,
  $paddingLink: 15px 20px 15px 20px,
  $minWidthLink: auto,
  $marginBlock: rem-calc(50)
) {
}
