@import '../../platformui/Header/styles.scss';
@import '../../platformui/Header/burger';
@import './tool';

.header {
  z-index: 10;
  box-shadow: 0 2px 6px 3px rgba($black, 0.15);
  margin-bottom: 0;
  position: relative;

  @media print {
    background: none !important;
    margin-left: unset;
    margin-right: unset;
    max-width: 52rem;
  }
}

body {
  transition: transform 0.2s ease-in-out;
}

body.header-panel-is-open {
  transform: translateY(65px);

  @include breakpoint(large) {
    transform: translateY(0);
  }
}

.header-panel {
  transition: transform 0.2s ease-in-out;
  transform: translateY(-100%);
  height: 140px;
  visibility: hidden;
  display: none;

  .header-panel-is-open & {
    z-index: 999;
    @include breakpoint(large) {
      transform: translateY(0);
      align-items: center;
      display: flex;
      filter: none;
    }
  }
  &[aria-hidden='false'] {
    visibility: visible;
  }
}

@include Header(
  $height: (
    60px,
    60px,
    84px,
  )
) {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  position: static;

  @include breakpoint(large) {
    min-height: 130px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media print {
    background: transparent;
    margin: 0 auto;

    min-height: unset !important;
  }

  &__main {
    display: flex;
    min-height: 40px;
    align-items: center;
    padding-left: 0;

    .c-mobile-headertool {
      margin-left: auto;
      display: flex;
      align-items: center;

      @include breakpoint(medium) {
        margin-right: 0;
      }

      @include breakpoint(large) {
        display: none;
      }
    }
    ul {
      padding-right: 10px;
      margin-top: 0px;
    }
  }
  .logo {
    display: flex;
    position: relative;
    margin-right: 20px;

    &:after {
      content: '';
      width: 1px;
      height: 30px;
      position: absolute;
      right: -10px;
      top: 10px;
    }

    @include breakpoint(large) {
      margin-right: 30px;

      &:after {
        top: auto;
        bottom: 0;
        right: 0;
        height: calc(100% - 30px);
      }
    }
  }
  &__navigation {
    @extend %hide-for-print;
    background-color: $secondary95;
    border-top: none;
    margin: 0;

    // make sure the menu does not wrap
    @include breakpoint(large) {
      border-top: 1px solid $neutral90;
      margin: 0 10px;
      background-color: transparent;
      flex-basis: 100%;
      flex-grow: 1;
    }
    // position the menu
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include breakpoint(large) {
      align-items: flex-start;
    }
  }
  &__top {
    z-index: 3;
    flex-basis: 100%;
    max-height: 90px;
    @include shadow(none);
    @include breakpoint(large) {
      flex-basis: 0;
      z-index: unset;
    }
    &__inner {
      background: transparent;
      min-height: 60px;
      display: flex;
      align-items: center;

      @include breakpoint(large) {
        min-height: 90px;
      }
    }
  }
}

@include Burger(
  $burger-color: $primary40,
  $burger-size: 31px,
  $lines-top: (
    -4px,
    -4px,
  ),
  $before-top: (
    11px,
    11px,
  ),
  $after-top: (
    -11px,
    -11px,
  ),
  $position-top: (
    0px,
    0px,
  ),
  $position-right: 4px,
  $lines-height: (
    2px,
    2px,
  )
) {
  .c-header {
    &--showbuildinfo {
      padding-top: unset;
    }
    &__burger {
      background: transparent;
      padding: 15px 6px;
      position: relative;
      right: auto;
      top: auto;

      @include focus-handler($offset: 0);
      @include breakpoint(medium) {
        right: 12px;
      }
    }
  }
}
