@import 'src/blocks/tab-container-block/ids-styles/expandable.scss';

//adjustments for the header and icon
.ids-expandable {
  &__headline {
    line-height: 1.5rem;
    font-size: 1.25rem !important;
    margin: 20px 0 !important;

    @include breakpoint(large) {
      line-height: 1.625rem;
    }
  }

  &__icon {
    content: url('../../framework/images/chevron-green.svg');
    background-color: #ffffff;
    display: inline-block;

    &--opened {
      transform: rotate(180deg);
    }
  }
}

//temporary fix for existing bug in IDS Expandable
.ids-expandable .ids-expandable__inner {
  flex-wrap: nowrap !important;
}

.accordion {
  border-bottom: 1px solid #353636;

  &__container {
    width: 100%;
    max-width: 720px;
  
    @include breakpoint(large) {
      width: 720px;
    }

    //Remove when IDS Alert is implemented
    .c-alert {
      background-color: #f0f0f0;
      padding: 0;
  
      &__icon {
        background-image: url('../../framework/images/info.svg') !important;
        background-size: 50px !important;
        height: 50px;
        width: 50px;
        margin: 0 20px 0 0;
      }
  
      &__no-title {
        @include breakpoint(small) {
          gap: 0;
        }
      }
  
      &__inner {
        margin: 30px;
      }
    }
  }

  &__info-item {
    padding: 4px 0 8px 0;
    p {
      margin: 0 !important;
    }
  }
}

.ended-outages {
  .accordion__info-item--details {
    margin-top: 28px;
  }
}
