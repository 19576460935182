$medium-dark-grey: #5a5a5a;

@mixin Footer(
  $className: 'c-footer',
  $color: #fff,
  $padding: (
    40px 0 25px,
    40px 0 20px,
    60px 0 35px,
  ),
  $boxShadowSite: (
    0 -2px 4px 0 rgba($black, 0.4),
    none,
  ),
  $logoSiteFooterRight: 0,
  $logoSiteFooterTop: -11px
) {
  .#{$className} {
    box-shadow: 0px -2px 6px 0px rgba($black, 0.5);
    position: relative;
    z-index: 2;

    &__content {
      padding: nth($padding, 1);
      position: relative;
      z-index: 1;

      @include breakpoint(medium) {
        padding: nth($padding, 2);
      }

      @include breakpoint(large) {
        padding: nth($padding, 3);
      }

      &__inner {
        position: relative;
        z-index: 2;
      }
    }

    &__site {
      box-shadow: nth($boxShadowSite, 1);
      position: relative;
      z-index: 2;

      @include breakpoint(large) {
        box-shadow: nth($boxShadowSite, 2);
      }

      &__inner {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        float: left;
        position: relative;
        width: 100%;

        @include breakpoint(large) {
          flex-direction: row;
          gap: 20px;
          align-items: center;
        }

        &__bottom-links {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include breakpoint(large) {
            column-gap: 100px;
            flex-direction: row-reverse;
          }

          a {
            display: inline-flex;
            align-items: center;
            height: 48px;
            width: 100%;
          }

          .c-linklist__list {
            display: flex;
            flex-direction: column;
            @include breakpoint(large) {
              column-gap: 50px;
              flex-direction: row;
              justify-content: flex-end;
            }
          }
        }

        &__logo {
          &__text {
            flex-direction: column;
            p {
              font-size: 0.875rem;
              margin-bottom: 0;
              width: 100%;
            }
          }
        }
      }
    }

    &__subheading {
      color: $white;
      font-size: rem-calc(24);
      font-weight: normal;
      margin: 0 0 20px;
    }

    &__logo {
      position: relative;
      @if ($logoSiteFooterRight != false) {
        right: 0;
      }

      @if ($logoSiteFooterTop != false) {
        top: -11px;
      }

      svg {
        height: 100%;
        object-fit: contain;
        position: relative;
        top: 0;
        width: 100%;
      }

      &__text {
        display: inline-block;
        text-decoration: none;
        min-width: fit-content;

        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @include breakpoint(large) {
        width: fit-content;
        max-width: 500px;
      }
    }

    .c-linklist &__logo__text p a {
      display: inline;
      text-decoration: underline;
    }

    @media print {
      display: none;
    }
    //Import site styles
    @content;
  }
}
