@import '../../platformui/Chapter/styles';
@import './toggle-chapter.scss';

@include Chapter($h2Color: $primary40, $h3h4Color: $neutral20) {
  .c-chapter {
    p,
    th,
    li {
      color: $neutral20;
    }

    @include breakpoint(large) {
      p:last-of-type {
        margin-bottom: 30px;
      }
      h3 {
        margin-bottom: 10px;
      }
    }
    @include clearfix();

    .c-imageblock--large {
      margin-top: 16px;
    }

    .c-imageblock--small-bleed,
    .c-imageblock--xsmall-bleed,
    .c-imageblock--xsmall,
    .c-imageblock--xsmall-reverse,
    .c-imageblock--mini-bleed,
    .c-imageblock--mini-reverse,
    .c-imageblock--mini {
      margin-top: 6px;
    }

    &__heading {
      &--is-anchornav {
        display: none;

        @include breakpoint(large) {
          display: inline-block;
        }
      }
      display: inline-block;
    }
  }
}
