@import '../../platformui/ArticleList/styles';

@include ArticleList(
  $headingColor: $accent40,
  $textColor: $neutral20,
  $borderBottomHover: solid 5px $accent30,
  $borderBottomFocus: false,
  $imageMarginBottom: (
    10px,
    10px,
    10px,
  ),
  $contentHeight--gt2: 100%,
  $contentHeight--gt3: 100%,
  $contentHeight--nth2: 100%,
  $maxHeightContentSmall: 100%,
  $headingMargin: 10px,
  $imgBorderRadius: 3px,
  $lineClamp: 3,
  $textHeight: 100%,
  $mobileLineClamp: 2,
  $mobileTextHeight: 100%,
  $linkIconColor: $accent40
) {
  @include focus-handler($color: c('black'), $offset: 8px);

  &__heading {
    @include secondary-font-family();
    line-height: 28px;
    &:focus {
      outline-offset: 0px;
    }
  }

  &__link {
    @include focus-handler($color: c('black'));
  }

  &--double-column {
    .c-article-list__link {
      display: block;
      @include icon('e820');

      &:before {
        width: auto;
      }
      &:after {
        border-bottom: 1px solid $neutral90;
      }
    }

    .c-article-list__content {
      display: flex;
      flex-direction: column;
    }

    .search-list__date {
      margin-bottom: 0px !important;
    }

    .c-article-list__text > p {
      -webkit-line-clamp: 5;
    }
  }
}

.c-article-list__notext .c-article-list__content {
  height: auto !important;
}
.c-article-list__noimage .c-article-list {
  padding: 10px 0;
}
.c-article-list .c-image.c-image {
  float: none;
  max-width: unset;
  max-height: unset;
  margin-right: 0;
}
