@import '../../platformui/Share/styles';

@include Share(
  $componentMargin: 60px 0 30px,
  $iconBgColor: transparent,
  $iconBgHoverColor: $accent30,
  $iconSocialColor: $accent40,
  $componentItemMarginLeft: 8px,
  $headingTextTransform: uppercase
) {
  .c-share {
    &__heading {
      color: $neutral20;
      font-size: rem-calc(24);
      letter-spacing: 1.5px;
      padding: 0;
      @include base-font-family();
    }

    &__list__item {
      a {
        @include focus-handler();
      }
    }
  }
}
