/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../../fonts/open-sans/open-sans-v20-latin-300.woff2') format('woff2'); /* Safari, Android, iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../../fonts/open-sans/open-sans-v20-latin-regular.woff2')
      format('woff2'); /* Safari, Android, iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../../fonts/open-sans/open-sans-v20-latin-italic.woff2')
      format('woff2'); /* Safari, Android, iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../../fonts/open-sans/open-sans-v20-latin-700.woff2') format('woff2'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
    url('../../fonts/poppins/poppins-v20-latin-600.woff2') format('woff2'),
    url('../../fonts/poppins/poppins-v20-latin-600.woff') format('woff');
}
