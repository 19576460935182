@import '../../platformui/Linklist/styles.scss';

@include Linklist($className: 'error404-linklist') {
  .error404-linklist {
    display: block;
    text-align: left;

    &:before {
      color: $accent40;
      text-decoration: none;
      content: '';
      display: block;
      height: 1px;
      margin-bottom: 20px;
      width: 43px;
    }

    @include breakpoint(medium) {
      display: inline-block;

      &:before {
        display: none;
      }
    }
    ul {
      text-align: center;
    }
    &__link {
      &:before {
        top: 8px;
      }
    }

    li {
      display: list-item;
      margin: 0 0 5px 0;
      padding: 0;

      a {
        color: $accent40;
        text-decoration: underline;
      }
      @include breakpoint(medium) {
        display: inline-block;
        margin: 0 40px 5px 0;

        .u-text-center & {
          margin: 0 20px 5px;
        }
      }
    }

    @include LinklistLink(
      $className: 'error404-linklist',
      $printColor: $neutral40,
      $iconFontSize: inherit,
      $iconTopMargin: 6px
    );
  }
}
