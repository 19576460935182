%formdropdown-chevron-down {
  border-style: solid;
  border-color: c('white');
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  margin-top: -6px;
}

%formdropdown-chevron-up {
  border-style: solid;
  border-color: c('white');
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-135deg);
  margin-top: -3px;
}

.c-forms__select {
  background-color: c('secondary95');
  border: 1px solid c('accent40');
  min-height: 50px;
  overflow: hidden;
  position: relative;

  * {
    font-family: inherit;
    font-size: inherit;
  }

  &:before {
    pointer-events: none;
    content: '';
  }

  &:after {
    content: '';
    top: 50%;
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    margin-top: -15px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: c('accent40');
  }

  &:before {
    @extend %formdropdown-chevron-down;
    z-index: 2;
    transform-origin: center center;
    position: absolute;
    right: 20px;
    transition: all 0.3s ease;
    top: 50%;
  }

  select {
    padding: 16px;
    color: c('neutral20');
    height: 50px;
    box-sizing: border-box;
    background: transparent;
    border: none;
    left: 0;
    overflow-wrap: anywhere;
    position: absolute;
    width: 100%;
    -webkit-appearance: none;
  }

  &--focus,
  &:focus-within {
    @include focus-style();

    &:before {
      content: '';
      @extend %formdropdown-chevron-up;
    }
  }

  &--disabled {
    color: c('medium-grey');
    background-color: c('white');
    border: 1px solid c('grey-200');

    &:before {
      border-color: c('white');
    }
  }

  &--multiple {
    height: auto;
    border: 1px solid c('accent40');
    background-color: c('white');

    &:before {
      content: unset;
      display: none;
    }

    &:focus-within {
      border: none;
      &:after,
      &:before {
        content: unset;
        display: none;
      }
    }

    select {
      height: auto;
      min-height: 200px;
      padding: 0;
      position: relative;
      width: 100%;

      &:focus-within {
        border: none;
        background-color: c('white');
      }

      option {
        padding: 5px 20px;

        &[disabled] {
          background-color: c('grey-100');
          font-style: italic;
        }
      }

      option:not([disabled]) {
        &:hover,
        &:active,
        &[selected],
        &:checked {
          background-color: c('accent90');
        }
      }
    }
  }
}
