@mixin LinkButton(
  $bgColor: white,
  $border: 2px solid black,
  $border-radius: rem-calc(30),
  $color: white,
  $hoverBgColor: grey,
  $hoverColor: lightblue,
  $fontSize: rem-calc(20),
  $padding: rem-calc(10) rem-calc(30),
  $text-decoration: none
) {
  @include base-font-family();
  max-width: 100%;
  text-align: center;
  border-radius: $border-radius;
  border: $border;
  background-color: $bgColor;
  box-sizing: border-box;
  transition: background-color 0.2s ease;

  a {
    display: block;
    color: $color;
    cursor: pointer;
    text-decoration: $text-decoration;
    font-size: $fontSize;
    word-wrap: break-word;
    border-radius: inherit;
    padding: rem-calc(10) rem-calc(30);
    @include breakpoint(medium) {
      padding: rem-calc(10) rem-calc(20);
    }

    &:focus,
    &:active {
      @include focus-handler();
      text-decoration: $text-decoration;
    }
  }

  &:hover {
    background-color: $hoverBgColor;
    border-color: $hoverBgColor;
    a {
      color: $hoverColor;
    }
  }

  @content;
}
