@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?87688836');
  src: url('../font/fontello.eot?87688836#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?87688836') format('woff2'),
    url('../font/fontello.woff?87688836') format('woff'),
    url('../font/fontello.ttf?87688836') format('truetype'),
    url('../font/fontello.svg?87688836#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?87688836#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-installningar:before {
  content: '\e800';
} /* '' */
.icon-arrow-1:before {
  content: '\e801';
} /* '' */
.icon-plus:before {
  content: '\e802';
} /* '' */
.icon-minus:before {
  content: '\e803';
} /* '' */
.icon-arrow-rigt-ext-circle:before {
  content: '\e809';
} /* '' */
.icon-print:before {
  content: '\e80d';
} /* '' */
.icon-enlarge-arrows:before {
  content: '\e80e';
} /* '' */
.icon-cancel:before {
  content: '\e810';
} /* '' */
.icon-right-small:before {
  content: '\e820';
} /* '' */
.icon-pdf:before {
  content: '\e82b';
} /* '' */
.icon-ppt:before {
  content: '\e831';
} /* '' */
.icon-xls:before {
  content: '\e832';
} /* '' */
.icon-doc:before {
  content: '\e833';
} /* '' */
.icon-search-1:before {
  content: '\e834';
} /* '' */
.icon-arrow-2:before {
  content: '\e835';
} /* '' */
.icon-search:before {
  content: '\e837';
} /* '' */
.icon-menu-x:before {
  content: '\e84e';
} /* '' */
.icon-map-pin-plus:before {
  content: '\e850';
} /* '' */
.icon-info-full:before {
  content: '\e853';
} /* '' */
.icon-phone:before {
  content: '\e856';
} /* '' */
.icon-info:before {
  content: '\e859';
} /* '' */
.icon-check:before {
  content: '\e85a';
} /* '' */
.icon-pointer:before {
  content: '\e85c';
} /* '' */
.icon-listview:before {
  content: '\e85e';
} /* '' */
.icon-blockview:before {
  content: '\e85f';
} /* '' */
.icon-file:before {
  content: '\e867';
} /* '' */
.icon-link-ext:before {
  content: '\f08e';
} /* '' */
.icon-twitter:before {
  content: '\f099';
} /* '' */
.icon-facebook:before {
  content: '\f09a';
} /* '' */
.icon-email-2:before {
  content: '\f0e0';
} /* '' */
.icon-angle-right:before {
  content: '\f105';
} /* '' */
.icon-angle-down:before {
  content: '\f107';
} /* '' */
