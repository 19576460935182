:root,
:host {
  /* Neutral colors */
  --IDS-COLOR-NEUTRAL-100: #fff;
  --IDS-COLOR-NEUTRAL-90: #CCCCCC;
  --IDS-COLOR-NEUTRAL-40: #727373;
  --IDS-COLOR-NEUTRAL-20: #353636;

  /* Accent colors */
  --IDS-COLOR-ACCENT-40: #40775E;
  --IDS-COLOR-ACCENT-30: #305A47;

  /* Focus */
  --IDS-FOCUS__OUTLINE: 0.125rem solid var(--IDS-COLOR-NEUTRAL-20);
  --IDS-FOCUS__OUTLINE-COLOR: var(--IDS-COLOR-NEUTRAL-20);

  /* Tabs */
  --IDS-TABS__BORDER-BOTTOM: 0.25rem solid var(--IDS-COLOR-NEUTRAL-90);
  --IDS-TAB_BACKGROUND-COLOR: var(--color-dust-background);
  --IDS-TAB_BOX-SHADOW: none;
  --IDS-TAB__BORDER: 0.063rem solid transparent;
  --IDS-TAB__BORDER-RADIUS: 0.188rem 0.188rem 0 0;
  --IDS-TAB__COLOR: var(--IDS-COLOR-ACCENT-40);
  --IDS-TAB--SELECTED_COLOR: var(--IDS-COLOR-ACCENT-30);
  --IDS-TAB--SELECTED-BORDER: 0.063rem solid var(--IDS-COLOR-NEUTRAL-40);
  --IDS-TAB--SELECTED__BOX-SHADOW: 0 0 0.25rem rgba(0, 0, 0, 0.3);
  --IDS-TAB--SELECTED__BEFORE-CONTENT: none;
  --IDS-TAB--SELECTED__AFTER-HEIGHT: 0.25rem;
  --IDS-TAB--SELECTED__AFTER-BOTTOM: -0.25rem;
  --IDS-TAB--SELECTED__AFTER-BACKGROUND-COLOR: var(--IDS-COLOR-ACCENT-30);

  /* Expandable */
  --IDS-EXPANDABLE__BORDER-BOTTOM: 0.063rem solid var(--IDS-COLOR-NEUTRAL-40);
    --IDS-EXPANDABLE__HEADLINE-COLOR: var(--IDS-COLOR-ACCENT-40);
    --IDS-EXPANDABLE__HEADLINE-FONT-FAMILY: var(--IDS-FONT-FAMILY-HEADING);
    --IDS-EXPANDABLE__HEADLINE-FONT-WEIGHT: 600;
    --IDS-EXPANDABLE__HEADLINE-FONT-SIZE: 1.75rem;
    --IDS-EXPANDABLE__HEADLINE-2-COLOR: var(--IDS-COLOR-NEUTRAL-20);
    --IDS-EXPANDABLE__ICON-2-COLOR: var(--IDS-COLOR-ACCENT-40);
    --IDS-EXPANDABLE--MOBILE__HEADLINE-FONT-SIZE: 1.75rem;
    --IDS-EXPANDABLE--CHILD__HEADLINE-FONT-SIZE: 1.25rem;
}
