// ******************
// Fonts
// ******************

$base-font: 'Open Sans',
arial,
sans-serif;
$secondary-font: 'Poppins',
arial,
sans-serif;

@mixin base-font-family() {
  font-family: $base-font;
}

@mixin secondary-font-family() {
  font-family: $secondary-font;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin focus-style($color: c('neutral20'), $offset: 4px) {
  outline: 2px solid $color;
  outline-offset: $offset;
}

@mixin focus-handler($color: c('neutral20'), $offset: 4px) {

  &:focus,
  &:focus-visible {
    @include focus-style($color, $offset);
  }
}

@mixin show-for-sr() {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

@mixin link-arrow($color: $accent40,
  $hoverColor: $accent30,
  $top: 8px,
  $left: 2px,
  $fontSize: 8px,
  $bgSize: 17px,
  $bgTop: $top - 4px,
  $paddingLeft: 25px,
  $useHoverCircle: true,
  $iconContent: 'e820',
  $rotate: 0deg,
  $bgColor: transparent) {
  padding-left: $paddingLeft;
  position: relative;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $paddingLeft;
  }

  @include icon($iconContent);

  &:before {
    color: $color;
    left: $left;
    font-size: $fontSize;
    position: absolute;
    top: $top;
    z-index: 2;

    [dir='rtl'] & {
      left: auto;
      right: 8px;
      transform: scaleX(-1);
    }
  }

  @if $useHoverCircle==true {
    &:after {
      background: $color;
      border-radius: 100%;
      content: '';
      display: block;
      height: $bgSize;
      left: 0;
      opacity: 0;
      position: absolute;
      top: $bgTop;
      width: $bgSize;
      z-index: 1;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      color: $hoverColor;
    }

    &:after {
      opacity: 1;
    }
  }

  @content;
}

@mixin iconbase($pseudo) {
  &:#{$pseudo} {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    width: 1em;

    @if $pseudo==before {
      margin-right: 0.2em;
    }

    @if $pseudo==after {
      margin-left: 0.2em;
    }

    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    [dir='rtl'] & {
      margin-left: 0.2em;
      margin-right: 0;
    }

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
}

@function ununicodeIcon($character) {
  @return unquote('"')+unquote(str-insert($character, '\\', 1))+unquote('"');
}

@mixin icon($content,
  $rtl-support: true,
  $include-base: true,
  $pseudo: before) {
  @if ($include-base==true) {
    @include iconbase($pseudo);
  }

  &:#{$pseudo} {
    content: ununicodeIcon($content);

    @if ($content=='\e808'or $content=='\e809'or $content=='\e80e') {
      [dir='rtl'] & {
        transform: scaleX(-1);
        filter: FlipH;
      }
    }

    @if $rtl-support==false {
      [dir='rtl'] & {
        transform: scaleX(1);
        filter: none;
      }
    }
  }

  @content;
}

@mixin nav-arrows($rotation: 0deg, $double: false) {
  @include icon('f105');

  @if $double==true {
    @include icon('f105', $pseudo: after);
  }

  &:before,
  &:after {
    // color: $denim;
    @include color('nav');
    position: absolute;
  }

  &:before {
    left: 0;
    top: 5px;
    transform: rotate($rotation);
  }

  &:after {
    left: 3px;
    top: 5px;
    transform: rotate($rotation);
  }
}
