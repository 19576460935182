.c-forms__radio {
  position: absolute;
  opacity: 0;

  + label {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      flex: 0 0 auto;
      background: c('secondary95');
      box-sizing: border-box;
      border-radius: 100%;
      border: 1px solid c('accent40');
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      text-align: center;
      margin-right: 10px;
      margin-top: 3px;
    }
  }
  &:checked {
    + label,
    + span {
      &:before {
        background-color: c('accent40');
        box-shadow: inset 0 0 0 3px c('secondary95');
      }
    }
  }
  &:focus {
    + span {
      @include focus-style();
    }
  }
  &:disabled {
    + label {
      &:before {
        box-shadow: inset 0 0 0 3px c('secondary95');
        border-color: c('muted');
        background: c('grey-200');
        cursor: default;
      }
    }
  }
  + label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }

  & + span,
  & + label {
    content: '';
    flex: 0 0 auto;
    background: c('secondary95');
    box-sizing: border-box;
    border-radius: 100%;
    border: 1px solid c('accent40');
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
  }

  &:checked + span,
  &:checked + label {
    background-color: c('accent40');
    box-shadow: inset 0 0 0 3px c('secondary95');
  }

  &:focus + span {
    @include focus-style();
  }

  &:disabled + span,
  &:disabled + label {
    box-shadow: inset 0 0 0 3px c('secondary95');
    border-color: c('muted');
    background: c('grey-200');
    cursor: default;
  }
}
