@import './field.scss';
@import './button.scss';
@import './select.scss';
@import './radio.scss';
@import './checkbox.scss';
@import '../../platformui/Framework/platform-mixins/StyledScrollbar.scss';

.EPiServerForms .Form__Element {
  margin: 10px 0 20px;
}

input.FormTextbox__Input {
  @extend .c-formfield;
  @include focus-handler();
}

textarea.FormTextbox__Input {
  @extend .c-formfield;
  min-height: 180px;

  @include StyledScrollbar(
    $track-margin: 5px,
    $thumb-background: c('accent40'),
    $track-border-color: transparent,
    $track-border-width: 3px,
    $track-background: c('grey-200'),
    $track-border-radius: 10px,
    $thumb-border-width: 0,
    $width: 10px,
    $height: 10px
  );

  &--error {
    @extend .c-formfield--error;
  }

  &--disabled:focus,
  &--disabled {
    @extend .c-formfield--disabled;
  }
  @include focus-handler();
}

.Form__Element [type='submit'],
.Form__Element [type='reset'] {
  @extend .c-formbutton;
  @extend .c-formbutton--primary;
  @include focus-handler();
  box-sizing: border-box;
  border-width: 1px;
}

.Form__Element [type='reset'] {
  @extend .c-formbutton__secondary-button;
}

.FormChoice {
  label {
    display: block;
    top: 3px;
  }
  label ~ label {
    margin-top: 10px;
  }
}

.Form__Element__Caption {
  display: block;
  color: c('muted-dark');
  margin-bottom: 8px;
}

.FormChoice__Input--Radio {
  @extend .c-forms__radio;
}

.FormChoice__Input--Checkbox {
  @extend .c-forms__checkbox;
}

.c-forms__select--has-focus {
  @extend .c-forms__select--focus;
}
.c-forms__select--is-multiple {
  @extend .c-forms__select--multiple;
}

.ValidationFail > .Form__Element__ValidationError {
  @extend .c-forms__error;
  margin-top: 15px;
}
