@import '../../platformui/MediaGallery/styles';

@include MediaGallery($iconFontSize: rem-calc(28),
  $iconRightPosition: 4px,
  $iconMarginTop: -26px,
  $paginationDotBgActive: $accent40,
  $paginationDotBg: #e5e5e5,
  $headingFontFamily: $secondary-font,
  $headingColorStandard: $primary40,
  $headingColorArticlePage: $primary40,
  $paginationNextPrevSvgColor: $accent40,
  $captionUnderlineColor: $black,
  $paginationNextPrevBgColor: transparent,
  $articlePageActiveItemBg: #f9f6f1,
  $standardHeadingAlignment: left,
  $articlePagePrevButtonIconBottomPositioning: 211px,
  $mainLinkInlinePadding: rem-calc(102),
  $standardHeadingInlinePadding: rem-calc(80),
  $focusColor: $neutral20,
  $idsThemeVariant: 'inera'

) {
  .c-linklist__link {
    color: $accent40;
    text-decoration: none;

    &:after {
      padding-left: rem-calc(15);
      font-family: 'fontello';
      content: '\e809';
    }

    &[href*='.inera.se'],
    &[href*='.inera.local'],
    &[href*='localhost'],
    &[href^='/'],
    &[href^='/link/'],
    &[href^='#'] {
      &:after {
        display: none;
        content: '';
      }
    }
  }

  .c-media-gallery__link {
    @include LinklistLink() {
      &:before {
        content: url('../../framework/images/arrow-right.svg');
      }
    }
  }

  .c-media-gallery__nextprev {
    background-color: transparent;
  }
}
