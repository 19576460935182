@import '../../../platformui/SearchBlock/list.scss';
@import '../../../platformui/SearchBlock/listitem.scss';

@include search-list();

@include search-list-item(
  $containerPadding: 20px 0,
  $itemBgColor: $white,
  $itemAlternatingBgColor: false
) {
  &__item {
    text-decoration: none;
    &__container {
      border-bottom: 1px solid $neutral40;
    }

    @include focus-handler($color: $neutral20, $offset: 0);

    &__heading {
      margin: 0 0 5px;
      font-size: 1rem;
      font-weight: bold;
      color: $accent40;
      @include breakpoint(large) {
        margin: 0 0 10px;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          @include focus-handler();
        }
      }
    }

    &__text {
      color: $neutral20;
      max-width: none;
    }
    .revised-and-published {
      margin-top: 10px;
      p {
        font-size: rem-calc(14);
        color: $neutral20;
        display: inline-block;
        margin: 0 10px 0 0;
        font-style: italic;
      }
    }
  }
}
