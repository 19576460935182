@mixin MediaGallery($icon: '',
  $iconFontSize: rem-calc(16),
  $iconRightPosition: 7px,
  $iconMarginTop: -25px,
  $currentFontWeight: 600,
  $headingFontFamily: inherit,
  $textFontFamily: inherit,
  $headingFontSize: 18px,
  $textFontSize: 16px,
  $headingFontColor: #000,
  $paginationDotBg: #e5e5e5,
  $paginationDotBgActive: #4b4b4b,
  $paginationNextPrevSvgColor: #000,
  $headingColorStandard: #000,
  $headingColorArticlePage: #000,
  $captionUnderlineColor: #000,
  $paginationNextPrevBgColor: rgba(#000, 0.6),
  $articlePageActiveItemBg: transparent,
  $articlePagePrevButtonIconBottomPositioning: 227px,
  $articlePageNextButtonIconTopPositioning: 250px,
  $standardHeadingAlignment: center,
  $mainLinkInlinePadding: 0px,
  $standardHeadingInlinePadding: 0px,
  $focusColor: #000,
  $idsThemeVariant: ''

) {
  .c-media-gallery {
    margin-block: rem-calc(30);

    @include breakpoint(large) {
      max-width: rem-calc(1160);
      margin-inline: auto;
      margin-bottom: rem-calc(60);

      &.full-width {
        max-width: rem-calc(1260);

        .c-media-gallery__items {
          @include focus-handler();
          max-width: 100%;
        }
      }
    }

    &__heading {
      margin-bottom: 20px;
      margin-inline: 20px;
      text-align: $standardHeadingAlignment;
      color: $headingColorStandard;

      @include breakpoint(large) {
        padding-inline: $standardHeadingInlinePadding;
      }

      &__article-page {
        text-align: left;
        font-size: rem-calc(20);

        @include breakpoint(large) {
          margin-left: 0;
        }
      }

      @media print {
        margin-bottom: 0;
      }
    }

    &__content {
      position: relative;
    }

    &__link {
      display: flex;
      justify-content: $standardHeadingAlignment;
      margin-block: rem-calc(30);
      position: relative;
      padding-inline: rem-calc(20);

      @include breakpoint(large) {
        padding-inline: $mainLinkInlinePadding;
      }

      &__article-page {
        justify-content: left;
        margin-left: rem-calc(20);

        @include breakpoint(large) {
          margin-left: 0;
        }

        padding-inline: unset;

        &:after {
          content: '';
          position: absolute;
          width: rem-calc(40);
          background-color: #4d4d4d;
          height: 2px;
          bottom: rem-calc(-10);
        }
      }
    }

    &__nextprev {
      cursor: pointer;
      height: 60%;
      position: absolute;
      top: 0;
      pointer-events: auto;
      width: 10%;

      &:focus {
        outline: 0;
      }

      &:focus-visible {
        outline: 2px solid $focusColor;
        outline-offset: 4px;
      }

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;

      }

      @include breakpoint(large) {
        width: 7.5%;
        height: 100%;

        @if $icon=='' {
          &:before {
            content: '';
            display: inline-block;
            height: 40px;
            width: 40px;
            position: absolute;
            right: -18px;
            transform: translate(-50%, -50%);
            z-index: 2;
            -webkit-mask: url('./resources/sharp-arrow.svg') no-repeat 50% 50%;
            mask: url('./resources/sharp-arrow.svg') no-repeat 50% 50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: $paginationNextPrevSvgColor;
          }

          &:after {
            display: none;
          }
        }

        @else {
          @include icon($icon);

          &:before {
            color: $white;
            font-size: $iconFontSize;
            position: absolute;
            transform: translate(-50%, -50%);
            right: $iconRightPosition;
            z-index: 2;
          }
        }

        &:after {
          background: $paginationNextPrevBgColor;
          border-radius: 100px;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
          content: '';
          height: 50px;
          margin-top: -25px;
          position: absolute;
          right: 0;
          width: 50px;
          top: 50%;
          z-index: 1;
        }

        &--prev {
          transform: rotate(180deg);
        }
      }
    }

    &__current {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
      color: $black;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0 0 3px 0;
      font-weight: $currentFontWeight;
      font-family: $headingFontFamily;
      padding: 3px 13px;
      position: absolute;
      margin: 0;
      top: 0;
    }

    &__items {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      /* Hides the scrollbar in different browsers */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      @include focus-handler();

      [dir='rtl'] & {
        direction: rtl;
      }

      &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }

      @include breakpoint(large) {
        max-width: 85%;
        margin-inline: auto;
      }
    }

    &__item {
      scroll-snap-align: center;
      flex: 0 0 auto;
      padding: 0;
      position: relative;

      @include breakpoint(large) {
        padding-inline: rem-calc(10.5);
      }

      &:first-child {
        margin-left: 20px;

        @include breakpoint(large) {
          margin-left: 0;
        }

        [dir='rtl'] & {
          margin-left: 0;
          margin-right: 20px;

          @include breakpoint(large) {
            margin-right: 0;
          }
        }
      }

      &:last-child {
        margin-right: 20px;

        @include breakpoint(large) {
          margin-right: 0;
        }

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 20px;

          @include breakpoint(large) {
            margin-left: 0;
          }
        }
      }

      //Standing images
      &.standing {
        .c-imageblock {
          padding-block: 15%;
          padding-inline: 15px;

          @include breakpoint(large) {
            padding: 0;
          }
        }

        .c-image {
          padding-top: 177.66% !important;

          @include breakpoint(large) {
            padding-top: 197.66% !important;
          }
        }

        .c-imageblock__image img {
          width: 100% !important;
        }
      }

      &__text-wrapper {
        margin-bottom: rem-calc(6);

        h2,
        h3,
        h4 {
          font-weight: 700;
          font-family: $headingFontFamily;
          font-size: $headingFontSize;
          color: $headingFontColor;
        }

        p {
          font-family: $textFontFamily;
          font-size: $textFontSize;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &__linklist {
        margin-top: rem-calc(15);

        & li {
          margin-bottom: 0;
        }
      }

      &__text {
        padding-bottom: rem-calc(15);

        p::after {
          background-color: $captionUnderlineColor;
          height: 1px;
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          width: rem-calc(40);

          @include breakpoint(large) {
            left: 10px;
          }

          [dir='rtl'] & {
            right: 0;
            left: unset;

            @include breakpoint(large) {
              right: 10px;
            }
          }
        }

        &__photo-cred {
          color: #636466;
          margin-block: rem-calc(10);

          p {
            font-size: rem-calc(14);
          }
        }
      }

      //Images in gallery
      &__image {
        transition: all 0.3s ease;

        .c-imageblock {
          padding-block: 15px;

          @include breakpoint(large) {
            padding: 0;
          }

          &__sensitive {
            padding-top: 58.66%;
            margin-inline: 20px;

            @include breakpoint(large) {
              margin: 0;
              padding-top: 66.66%;
            }
          }

          .c-image {
            padding-top: 58.66%;
            overflow: visible;

            img {
              filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2));
            }

            @include breakpoint(large) {
              padding-top: 66.66%;

              img {
                filter: none;
              }
            }
          }
        }
      }

      //Videos in gallery
      &__video {
        transition: all 0.3s ease;

        .c-video {
          padding-block: 10%;
          padding-inline: 15px;

          @include breakpoint(large) {
            padding: 0;
          }

          &__heading {
            margin-block: rem-calc(15);
            font-weight: 700;
            font-family: $headingFontFamily;
            font-size: $headingFontSize;
            color: $headingFontColor;
          }

          &__caption {
            margin: 0;
            color: #353535;

            p {
              font-family: $textFontFamily;
              font-size: $textFontSize;
            }
          }

          &__container {
            padding-top: 45%;

            @include breakpoint(large) {
              padding-top: 56.25%;
            }

            &__wrapper {
              opacity: 0;
              visibility: hidden;
              max-height: 0;
              transition: all 0.3s ease;

              @include breakpoint(large) {
                opacity: 1;
                visibility: visible;
                max-height: 1000px;
              }

              &:after {
                background-color: black !important;
                max-width: rem-calc(40);
              }
            }
          }

          &.standing {
            padding-block: 15%;

            @include breakpoint(large) {
              padding: 0;
            }

            .c-video__container {
              padding-top: 160%;

              @include breakpoint(large) {
                padding: 0 !important;
              }
            }
          }
        }
      }

      &__text-container {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        transition: all 0.3s ease;

        @include breakpoint(large) {
          opacity: 1;
          visibility: visible;
          max-height: 1000px;
        }
      }

      // Active slide styling (mobile only)
      &.active {
        &.standing {
          .c-imageblock {
            padding: 0;
          }

          .c-image {
            padding-top: 197.66% !important;
          }

          .c-imageblock__image img {
            width: 100% !important;
          }
        }

        .c-media-gallery__item {
          &__image {
            .c-imageblock {
              padding-block: 0;

              .c-imageblock__sensitive {
                padding-top: 66.66%;
                margin-inline: 0;
              }

              .c-image {
                padding-top: 66.66%;

                img {
                  filter: none;
                }
              }
            }
          }

          &__video {
            .c-video {
              padding: 0;

              &.standing {
                .c-video__container {
                  padding-top: 178.05%;
                }
              }

              .c-video__container {
                padding-top: 56.25%;
              }

              .c-video__container__wrapper {
                opacity: 1;
                visibility: visible;
                max-height: 1000px;
              }
            }
          }
        }

        .c-media-gallery__item__text-container {
          opacity: 1;
          visibility: visible;
          max-height: 1000px;
        }

        .c-media-gallery__current {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .c-image {
      @media print {
        display: flex;
        justify-content: center;
      }
    }

    //Article page styling
    &.article-page {
      margin-inline: -20px;
      max-width: rem-calc(620);

      @include breakpoint(large) {
        margin-inline: unset;
      }

      .c-media-gallery {
        &__heading {
          color: $headingColorArticlePage;
          font-size: rem-calc(22);

          @include breakpoint(large) {
            padding-inline: unset;
            font-size: rem-calc(24);
          }
        }

        &__link {
          @include breakpoint(large) {
            padding-inline: unset;
          }
        }

        @include breakpoint(large) {
          &__current {
            display: block !important;
          }

          &__items {
            display: block;
            max-width: 100%;
            overflow: visible;
            position: relative;
          }

          &__nextprev {
            &--prev {
              left: -76px;

              &::after {
                top: auto;
                bottom: 225px;
              }

              &::before {
                bottom: $articlePagePrevButtonIconBottomPositioning;
              }
            }

            &--next {
              right: -76px;

              &::after {
                top: 250px;
              }

              &::before {
                top: $articlePageNextButtonIconTopPositioning;
              }
            }
          }

          &__item {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease;

            padding: 0 !important;

            @if $idsThemeVariant=='1177' {
              border: 1px solid #dadbdc;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px 0px;

              &.standing {
                border-radius: 0px 10px 10px 0px;
              }

              .c-image {
                border-radius: 0;
              }
            }

            @else if $idsThemeVariant=='inera' {
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;

              &.standing {
                border-radius: 0;
              }

              .c-image {
                border-radius: 0;
                background-color: $articlePageActiveItemBg;
              }
            }

            @else {
              box-shadow: 2px 3px 4px 2px rgba(0, 0, 0, 0.1);
              border-radius: 0px 0px 5px 5px;
            }

            &__text-wrapper {
              padding: 0 20px 20px;
              word-break: break-word;

              &.article-page {
                padding: rem-calc(3) rem-calc(50) rem-calc(23);
              }
            }

            &__text {
              padding-bottom: rem-calc(2);

              p::after {
                display: none;
              }
            }

            &__video {
              .standing {
                flex-flow: row;

                .c-video {
                  &__container__wrapper {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                  }

                  &__caption {
                    padding-inline: 0;
                  }
                }
              }

              .c-video__container__wrapper {
                padding: 0 20px 20px 20px;

                p {
                  margin-bottom: 0;
                }

                &:after {
                  display: none;
                }
              }
            }

            &.active {
              position: relative;
              opacity: 1;
              visibility: visible;
              transition: all 0.3s ease;

              @include breakpoint(large) {
                background-color: $articlePageActiveItemBg;
              }
            }

            &.standing {
              display: flex;

              .c-imageblock {
                margin-bottom: 0;

                &--is-sensitive {
                  .c-imageblock__sensitive {
                    padding-top: 0;

                    &__content {
                      position: relative;
                      width: 280px;
                      top: unset;
                      transform: unset;
                      left: unset;
                      height: 554px;
                      display: flex;
                      justify-content: center;
                      flex-flow: column;
                    }
                  }
                }
              }

              .c-image {
                width: 300px;
              }

              .c-media-gallery__item__text-container {
                display: flex;
                flex-flow: column;
                justify-content: center;
              }

              .c-media-gallery__item__text-wrapper {
                padding-block: rem-calc(20);
              }
            }
          }
        }
      }
    }

    .c-linklist__link {
      text-decoration: none !important;
    }

    //Pagination styling
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-block: rem-calc(30px);
    }

    .pagination-dot {
      padding: 0;
      background-color: $paginationDotBg;
      border: none;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;

      &:focus-visible {
        outline: 2px solid $focusColor;
        outline-offset: 4px;
      }
    }

    .pagination-dot.active {
      background: $paginationDotBgActive;
      border-radius: 50px;
      height: 13px !important;
    }

    @content;
  }
}
