@import '../../platformui/Linklist/styles.scss';

// modifier class, .teaser-linklist, in platformui
.c-linklist.teaser-linklist {
  .c-linklist__list {
    padding: 0;
    li {
      margin-top: 15px;
    }
  }

  @include LinklistLink($icon: '\F105') {
    color: $accent40;
    text-decoration: none;
    margin-top: 10px;

    &[href$="/"],
        &[href*="/#"],
        &[href*="http://"],
        &[href*="https://"]
    {
      &:before {
        font-size: 20px;
        top: 3px;
      }
    }

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 18px;
    }

    span {
      border-bottom: none;
      background-image: none;
      color: $accent40;
      margin-right: 10px;

      &:hover {
        border-bottom: 0.5px solid $accent40;
        background-image: none;
      }
    }
  }
}
