@mixin SearchLinkItem(
  $borderColor: blue,
  $oddBgColor: green,
  $color: yellow,
  $iconSize: 10px,
  $iconTopMargin: 7px,
  $iconColor: $color,
  $paddingWithSectionWrapper: unset
) {
  .search-link-item {
    border-radius: 10px;
    padding: 12px;

    @include WithSectionWrapper() {
      width: calc(50% - 10px);
      display: inline-block;
    }

    &:nth-child(even) {
      @include WithSectionWrapper() {
        margin-left: 10px;

        [dir='rtl'] & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
    }

    &:nth-child(odd) {
      background: $oddBgColor;
      @include WithSectionWrapper() {
        background: unset;
        margin-right: 10px;

        [dir='rtl'] & {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }

    &:nth-child(4n-2),
    &:nth-child(4n-3) {
      @include WithSectionWrapper() {
        background: $oddBgColor;
      }
    }

    &__link {
      display: inline-block;
      text-decoration: none;
      padding-left: 25px;
      position: relative;

      @include WithSectionWrapper() {
        padding-left: $paddingWithSectionWrapper;
      }

      &:before {
        color: $iconColor;
        font-size: $iconSize;
        left: 0;
        position: absolute;
        top: $iconTopMargin;

        @include WithSectionWrapper() {
          content: none;
        }

        [dir='rtl'] & {
          left: unset;
          right: 0;
        }
      }

      &:hover {
        span {
          border-color: $borderColor;
        }
      }

      span {
        border-bottom: 1px solid rgba($borderColor, 0.4);
        color: $color;
        display: inline;

        @include WithSectionWrapper() {
          border: none;
        }
      }
    }
    @content;
  }
}
