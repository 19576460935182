@import '../../platformui/AOListBlock/styles.scss';

@include AOList(
  $backgroundColor: (
    $white,
    $accent40,
  ),
  $backgroundColorActive: (
    $accent30,
    $accent30,
  ),
  $backgroundColorInactive: (
    $neutral99,
    $neutral99,
  ),
  $backgroundColorSelected: (
    $accent40,
    $accent40,
  ),
  $border: none,
  $color: $neutral20,
  $colorInactive: $neutral20,
  $colorSelected: (
    $white,
    $white,
  ),
  $colorActive: (
    $white,
    $white,
  ),
  $fontSize: (
    rem-calc(10),
    rem-calc(20),
  ),
  $fontSizeSelected: (
    rem-calc(16),
    rem-calc(20),
  ),
  $fontWeight: (
    normal,
    normal,
  ),
  $fontWeightSelected: (
    bold,
    normal,
  ),
  $heightItem: 50px,
  $margin: (
    0,
    0 auto,
  ),
  $textAlign: left,
  $transformScale: 1.1,
  $width: (
    40px,
    calc(100% + 10px),
  ),
  $widthItem: 50px
) {
  position: absolute;
  right: 0;

  @include breakpoint(medium) {
    position: relative;
  }

  &--is-fixed {
    position: fixed;
    top: 0;

    @include breakpoint(medium) {
      position: relative;
    }
  }

  &--is-bottom {
    position: absolute;
    bottom: 0;

    @include breakpoint(medium) {
      position: relative;
    }
  }

  a {
    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
    }
  }
}
.c-anchorlinks {
  @include breakpoint(small only) {
    position: static;
    right: auto;
  }

  &__item {
    @include breakpoint(small only) {
      &:active {
        background: none;
        color: $neutral20;
      }
      &--selected {
        background-color: $neutral99;
        color: $neutral20;
      }
    }
    @include breakpoint(medium) {
      font-weight: bold;
      &:focus,
      &:active {
        color: $neutral20;
        background-color: $accent30;
      }
      &--selected {
        background-color: $accent30;
      }
      &--matched {
        color: $white;

        &:focus,
        &:hover {
          color: $white;
          background-color: $accent30;
        }
      }
    }
  }
}

.ao-list-track-container {
  position: relative;

  @include breakpoint(small only) {
    @media screen {
      // negate the column and row margin/padding.
      margin-right: -1rem;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      height: 100%;
      justify-content: space-between;
      border-top: solid 1px $neutral20;
    }
  }
}

.ao-list {
  &__container {
    @media print {
      display: none;
    }

    bottom: 0;
    position: absolute;
    right: -20px;
    top: 0;

    @include breakpoint(small only) {
      top: unset;
      right: unset;
      bottom: 100px;
      position: sticky;
      align-self: flex-end;
    }

    @include breakpoint(medium) {
      @media screen {
        border-bottom: solid 1px $neutral20;
        border-top: solid 1px $neutral20;
        margin-bottom: 10px;
        margin-left: calc(-16.66667% - 10px);
        margin-right: calc(-16.66667% - 10px);
        padding-bottom: 20px;
        padding-top: 30px;
        position: relative;
        right: 0;
      }
    }

    @include breakpoint(large) {
      @media screen {
        margin-left: calc(-33.33334% - 10px);
        margin-right: 0;
      }
    }

    & > div {
      height: 100%;
      @include breakpoint(small only) {
        height: auto;
      }
    }
  }

  &__wrapper {
    @include breakpoint(medium) {
      position: relative;
    }

    &__letter {
      display: none;

      @include breakpoint(medium) {
        @media screen {
          color: $neutral20;
          font-weight: bold;
          font-size: 1.5rem;
          padding: 0;
          background-color: c('secondary90');
          border: none;
          border-radius: 50%;
          display: block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          position: absolute;
          text-align: center;
          margin: 0;
          right: 0;
          text-transform: uppercase;
          top: 30px;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      @include breakpoint(medium) {
        @media screen {
          min-height: 115px;
          padding: 30px 100px 25px 0;
          width: 100%;
          border-top: solid 1px $neutral40;
        }
      }
      @include breakpoint(large) {
        @media screen {
          padding: 30px 0 10px;
        }
      }

      li {
        margin-bottom: 15px;

        @include breakpoint(large) {
          @media screen {
            margin-left: calc(25% + 10px);
          }
        }
      }
    }

    &__back {
      bottom: 0;
      display: inline-block;
      padding: 0;
      position: absolute;
      overflow: hidden;
      right: 0;
      height: 0;
      width: 0;

      &:focus {
        border: solid 1px $neutral40;
        border-bottom: none;
        height: auto;
        opacity: 1;
        outline: none;
        overflow: visible;
        padding: 6px;
        width: auto;

        span {
          display: inline-block;
          padding: 4px;
          outline: 1px dotted $neutral40;
          outline-offset: 1px;
        }
      }

      @media print {
        display: none;
      }
    }
  }

  &__drugs {
    margin-left: -7px;
    margin-right: -7px;
    padding-left: 7px;
    padding-right: 7px;

    @include breakpoint(medium) {
      @media screen {
        border-bottom: solid 1px $neutral40;
        margin-left: calc(-16.66667% - 10px);
        margin-right: calc(-16.66667% - 10px);
        padding-left: 0;
        padding-right: 0;
      }
    }
    @include breakpoint(large) {
      @media screen {
        margin-left: calc(-33.33334% - 10px);
        margin-right: 0;
      }
    }
    & > div {
      &:first-child {
        .ao-list__wrapper__list {
          border-top: none;
        }
      }
    }
  }
}
