//THIS FILE SHOULD BE REMOVED IN FAVOR OF PACKAGE FROM IDS
//# From: https://design.inera.se/?path=/docs/plain-css-expandable--docs

@use './ids-variables.css' as *;

.ids-expandable {
  border-bottom: var(--IDS-EXPANDABLE__BORDER-BOTTOM);
}

@media (min-width: 640px) {
  .ids-expandable {
    min-height: 1.75rem;
    min-width: 1.75rem;
  }
}

.ids *, .ids *::before, .ids *::after {
    box-sizing: border-box;
}

.ids-expandable .ids-expandable__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 4.375rem;
  text-align: start;
  gap: 0.625rem;
}

@media (min-width: 640px) {
    .ids-expandable .ids-expandable__inner {
        flex-wrap: nowrap;
    }
}

.ids-expandable .ids-expandable__headline, .ids-expandable ::slotted([slot=headline]) {
    color: var(--IDS-EXPANDABLE__HEADLINE-COLOR);
    font-size: var(--IDS-EXPANDABLE__HEADLINE-FONT-SIZE);
    font-family: var(--IDS-EXPANDABLE__HEADLINE-FONT-FAMILY);
    font-weight: var(--IDS-EXPANDABLE__HEADLINE-FONT-WEIGHT);
    margin: 0;
    text-align: start;
}

@media (max-width: 1024px) {
    .ids-expandable .ids-expandable__headline, .ids-expandable ::slotted([slot=headline]) {
        font-size: var(--IDS-EXPANDABLE--MOBILE__HEADLINE-FONT-SIZE);
    }
}

.ids-expandable.ids-expandable--expanded>.ids-expandable__content {
    display: block;
}

.ids-expandable .ids-expandable__content {
    padding-bottom: 1.25rem;
    display: none;
}