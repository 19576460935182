@mixin Anchor($btnColor: #666666,
    $hoverColor: #66666675,
    $spanColor: $btnColor ) {
    .c-anchor__button {
        color: $btnColor;
        text-decoration: none;
        max-width: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 3px;
        margin-top: 30px;
        min-height: 50px;

        span {
            color: $spanColor;
            font-size: 1.125rem;
            font-weight: 700;
        }

        &:hover {
            span {
                color: $hoverColor;
            }
        }

    }

    @content;
}