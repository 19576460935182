@mixin AnchorNav(
  $mainColor: #3b6390,
  $fontColor: $mainColor,
  $focusColor: #696969
) {
  .anchor-nav-track {
    display: none;
    @include breakpoint(medium) {
      display: block;
      flex: 1 1 auto;
      position: relative;
      margin-top: -30px;
      padding-top: 30px;
    }
    @media print {
      display: none;
    }
  }
  .anchor-nav {
    display: none;
    max-height: 100%;
    padding-bottom: 30px;
    width: 100%;

    @include breakpoint(large) {
      display: block;
    }

    &:before {
      background: $white;
      box-shadow: -2px 2px 2px rgba($black, 0.15);
      content: '';
      display: block;
      height: 22px;
      position: absolute;
      left: -9px;
      top: 49px;
      width: 22px;
      transform: rotate(45deg);

      [dir='rtl'] & {
        background-color: transparent;
        border-top: 16px solid transparent;
        border-right: none;
        border-bottom: 16px solid transparent;
        border-left: 16px solid $white;
        box-shadow: none;
        filter: drop-shadow(3px 0px 2px rgba($black, 0.15));
        height: 0;
        left: auto;
        right: -13px;
        transform: rotate(0deg);
        width: 0;
      }
    }

    @media print {
      display: none;
    }

    &--is-fixed {
      max-width: rem-calc(1280);
      position: fixed;
      top: 30px;

      &:before {
        top: 18px;
      }
    }

    &--is-bottom {
      position: absolute;
      bottom: -30px;

      &:before {
        top: 18px;
      }
    }

    &--is-hidden {
      display: none;
    }

    &__inner {
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba($black, 0.3);
      max-height: 100%;
      overflow: hidden;
      /*width: calc(100% - 20px);*/

      .anchor-nav--is-fixed & {
        width: calc(25% - 20px);
      }
    }

    &__navigation {
      border-top: 5px solid $mainColor;
      padding: 15px 20px 20px;
    }

    &__heading {
      border-bottom: solid 1px rgba($mainColor, 0.3);
      font-size: rem-calc(20);
      margin: 0;
      padding-bottom: 12px;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      [dir='rtl'] & {
        font-size: rem-calc(18);
      }

      li {
        border-bottom: solid 1px rgba($mainColor, 0.3);
        display: block;
        padding-bottom: 12px;
        padding-top: 12px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      &__link {
        color: $fontColor;
        display: block;
        text-decoration: none;

        @include focus-handler($color: $focusColor, $offset: 2px);

        @include link-arrow();

        &--is-selected {
          &:before {
            color: $white;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
    @content;
  }
}
