@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Pagination/styles';
@import '../../platformui/Spinner/styles';
@import './list/styles';

@include spinner($bgColor: $white, $margin: 0 auto) {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@include SearchList() {
  &__footer {
    display: flex;

    button {
      background-color: $white;
      &:hover,
      &:active,
      &:focus {
        color: $white;
        background-color: $accent30;
      }
      .c-pagination__text {
        color: unset;
      }
    }
  }
}

@include SearchField(
  $searchFieldLabel: $accent40,
  $buttonBg: $accent40,
  $buttonColor: $white,
  $buttonBorderRadius: (
    0 3px 3px 0,
    3px,
  ),
  $buttonWidthLarge: 95px,
  $buttonHoverBg: $accent30,
  $searchFieldMarginBottom: (
    10px,
    20px,
  )
) {
  .c-search__field {
    &__button {
      font-size: 1rem;
      letter-spacing: normal;
      text-transform: uppercase;
      width: 50px;
      height: 40px;
      border-radius: 3px;

      @include breakpoint(large) {
        font-size: 1.5rem;
        width: 90px;
        height: 60px;
      }

      &:hover,
      &:focus {
        @include focus-handler();
        box-shadow: 0 3px 8px 0 rgba($black, 0.3);
      }
    }
    &__label {
      left: 7px;
      top: 4px;
      font-size: 20px;
      @include breakpoint(large) {
        left: 14px;
        font-size: 30px;
      }
      i {
        display: block;
      }
    }
  }

  @include SearchFieldQuery(
    $searchFieldBg: $secondary95,
    $border: solid 1px $accent40,
    $borderRadius: (
      3px 0 0 3px,
      3px,
    ),
    $searchFieldFocusBorder: none,
    $searchFieldFocusShadow: inset 0 5px 10px 0 rgba($black, 0.2),
    $paddingLeft: (
      20px,
      56px,
    )
  ) {
    .c-search__field {
      padding-right: 60px;
      z-index: unset;
      @include breakpoint(large) {
        padding-right: 90px;
      }
      &__query {
        font-size: rem-calc(20);
        color: $neutral20;
        width: 100%;
        height: 40px;
        padding-left: 40px;
        border-radius: 3px;
        border-right: 1px solid $accent40;

        @include breakpoint(large) {
          padding-left: 66px;
          height: 60px;
          font-size: rem-calc(20);
        }

        &:hover,
        &:focus {
          @include focus-handler();
        }

        @include breakpoint(large) {
          width: calc(100% - 20px);
        }

        &::placeholder {
          color: $neutral20;
          font-style: italic;
        }
      }
    }
  }

  @include SearchAutocomplete(
    $panelBg: $white,
    $panelPadding: 0,
    $itemHoverBg: $neutral90,
    $bottomBorderRadius: 3px,
    $topBorderRadius: 3px,
    $itemFontStyle: normal,
    $itemPadding: 12px 20px
  ) {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    margin-top: 4px;
    min-width: 100%;
    left: 0;
    right: auto;
    z-index: 2;

    @include breakpoint(medium) {
      left: 0;
      right: auto;
    }

    @include breakpoint(large) {
      left: 10px;
      margin-top: -5px;
      max-width: calc(100% - 166px);
      min-width: 503px;
      right: auto;
    }

    &__item {
      a {
        color: $black;
        font-size: rem-calc(18);
        line-height: 1.44;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include pagination() {
  background-color: $white;
  border: 1px solid $accent40;
  border-radius: 3px;
  margin: 0 10px;
  width: 100%;
  padding: 12px 40px;
  text-transform: uppercase;

  &__text {
    @include base-font-family();
    color: $accent40;
    font-size: rem-calc(20);
  }

  &:hover,
  &:focus {
    @include focus-handler($color: $neutral20, $offset: 5px);
    box-shadow: 0 3px 8px 0 rgba($black, 0.3);
  }

  @include breakpoint(large) {
    margin: 0 auto;
    padding: 12px 50px;
    width: 300px;
  }
}

@include SearchTools($borderColor: $neutral40, $borderOpacity: 1) {
  .c-search__tools {
    margin-top: 0;
    margin-bottom: 0;
    @include breakpoint(large) {
      margin-top: 50px;
    }
    .c-searchlist__heading {
      @include base-font-family();
      font-size: 1rem;
      line-height: 26px;
      margin: 12px 20px 12px 0;
      color: $neutral20;
    }
  }
}
