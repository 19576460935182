@import '../../platformui/Linklist/styles.scss';

@include Linklist(
  $leftItemPadding: 30px,
  $iconFontSize: 16px,
  $ulMargin: 20px 0 0 0
) {
  .c-linklist {
    &--block {
      margin-bottom: 60px;
    }

    &__list {
      li {
        &[dir='rtl'] {
          text-align: right;
        }

        // remove padding in chapter block

        .c-chapter & {
          padding: 0;
        }
      }
    }

    @include LinklistLink(
      $iconTopMargin: 9px,
      $iconColor: $accent40,
      $documentColor: $accent40
    ) {
      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: url('../../framework/images/arrow-right.svg');
      }

      .icon-arrow-rigt-ext-circle {
        &:before {
          content: url('../../framework/images/extern-link.svg');
        }
      }
    }
  }
}
