@mixin SearchArticleList($dateTextColor: #9e9e9e, $dateTextSize: 12px) {
  .c-search-main-content .link-item-list {
    .search-list__item {
      &:last-of-type {
        border-bottom: 1px solid rgba(#666, 0.4);
      }
    }
  }

  .c-search-list {
    margin-top: 40px;
    .c-image {
      float: left;
      max-width: 200px;
      max-height: 130px;
      margin-right: 25px;
    }
    .search-list {
      &__title {
        font-size: 1rem;
      }
      &__text {
        font-size: 0.875rem;
      }

      &__content {
        float: none;
        overflow: hidden;
        max-height: 130px;
      }
      &__item {
        padding: 10px 0;
        border-top: 1px solid rgba(#666, 0.4);
        box-sizing: content-box;

        // Use one column when grid is not supported.
        // fallback start
        margin-left: auto;
        margin-right: auto;
        max-width: 42em;
        width: 100%;

        &:only-of-type {
          max-width: 100%;
        }
        // Falback end

        p {
          margin: 10px 0 0 0;
          max-height: 70px;
        }
      }
      &__item--has-img {
        min-height: 130px;
        padding: 20px 0;
        p {
          max-height: 130px;
        }
      }
      &__date {
        display: block;
        font-size: $dateTextSize;
        color: $dateTextColor;
        letter-spacing: 1px;
      }
      &__title {
        line-height: 1.5;
        margin: 0;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .link-item-list {
      &__button {
        @extend .ic-forms__secondary-button !optional;
        color: #666;
        text-decoration: none;
        max-width: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 3px;
        margin-top: 30px;
        min-height: 50px;
        span {
          color: #666;
          font-size: 1.125rem;
          font-weight: 700;
        }
        &:hover {
          span {
            color: white;
          }
        }
      }
    }
    .u-article--center {
      justify-content: center;
    }
  }
  @content;
}
