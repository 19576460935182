.c-forms__checkbox {
  position: absolute;
  opacity: 0;

  &:checked {
    + label {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' heigh='12' viewBox='0 0 15 12'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2300706E'%3E%3Cg%3E%3Cg%3E%3Cpath d='M14.574.436c.38.38.38.998 0 1.378L12.31 4.08l-1.38 1.379-5.515 5.515c-.037.037-.076.071-.118.101-.043.075-.096.146-.16.21-.381.381-.998.381-1.38 0L2.379 9.908.539 8.068c-.38-.38-.38-.998 0-1.379.381-.38.999-.38 1.38 0l2.51 2.512L9.552 4.08 10.93 2.7 13.195.436c.381-.381.998-.381 1.38 0z' transform='translate(-402 -192) translate(399 186) translate(0 3) translate(3 3)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: center center;
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }
  &:focus {
    + span {
      @include focus-style();
    }
  }
  &[disabled],
  &:disabled {
    + label {
      &:before {
        cursor: default;
        border-color: transparent;
      }
    }
  }
  &[disabled]:checked,
  &:disabled:checked {
    + label {
      &:before {
        cursor: default;
        border-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' heigh='12' viewBox='0 0 15 12'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2300706E'%3E%3Cg%3E%3Cg%3E%3Cpath d='M14.574.436c.38.38.38.998 0 1.378L12.31 4.08l-1.38 1.379-5.515 5.515c-.037.037-.076.071-.118.101-.043.075-.096.146-.16.21-.381.381-.998.381-1.38 0L2.379 9.908.539 8.068c-.38-.38-.38-.998 0-1.379.381-.38.999-.38 1.38 0l2.51 2.512L9.552 4.08 10.93 2.7 13.195.436c.381-.381.998-.381 1.38 0z' transform='translate(-402 -192) translate(399 186) translate(0 3) translate(3 3)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }
  + label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }

  & + span,
  & + label {
    content: '';
    flex: 0 0 auto;
    background: c('secondary95');
    box-sizing: border-box;
    border: 1px solid c('accent40');
    border-bottom: 2px solid c('accent40');
    border-radius: 1px;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
  }

  &:checked + span,
  &:checked + label {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='12' viewBox='0 0 15 12'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2300706E'%3E%3Cg%3E%3Cg%3E%3Cpath d='M14.574.436c.38.38.38.998 0 1.378L12.31 4.08l-1.38 1.379-5.515 5.515c-.037.037-.076.071-.118.101-.043.075-.096.146-.16.21-.381.381-.998.381-1.38 0L2.379 9.908.539 8.068c-.38-.38-.38-.998 0-1.379.381-.38.999-.38 1.38 0l2.51 2.512L9.552 4.08 10.93 2.7 13.195.436c.381-.381.998-.381 1.38 0z' transform='translate(-402 -192) translate(399 186) translate(0 3) translate(3 3)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }

  &:focus + span,
  &:focus + label {
    @include focus-style();
  }

  &:disabled + span,
  &:disabled + label {
    cursor: default;
    border-color: transparent;
  }
}
