@mixin StyledScrollbar(
  $width: 14px,
  $height: 14px,
  $track-background: transparent,
  $track-border-radius: 0,
  $track-border-color: transparent,
  $track-border-width: 0,
  $thumb-background: get($colors, "cta"),
  $thumb-border-radius: rem-calc(8),
  $thumb-border-color: transparent,
  $thumb-border-width: rem-calc(3),
  $track-margin: 0
) {
  scrollbar-color: $thumb-background $track-background;
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $thumb-border-radius;
    border: $thumb-border-width solid $thumb-border-color;
    background: $thumb-background;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    margin: $track-margin;
    background: $track-background;
    border-radius: $track-border-radius;
    border-color: $track-border-color;
    border-width: $track-border-width;
    border-style: solid;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
