@mixin iconBox($pseudo, $icon) {
  @include icon($content: $icon, $pseudo: $pseudo);
  &:#{$pseudo} {
    color: $neutral20;
    border: 1px solid $neutral40;
    border-radius: 3px;
    display: inline-block;
    width: rem-calc(14);
    height: rem-calc(14);
    line-height: rem-calc(13);
    font-size: rem-calc(11);
    @if $pseudo == 'after' {
      margin-left: 5px;
    } @else {
      margin-right: 5px;
    }
  }
}

.powerbi-iframe-container {
  padding-top: 20px;
  padding-bottom: 20px;
  clear: both;

  iframe {
    border: none;
    margin-left: -15px;
    margin-right: -15px;
  }
}

%uppercase {
  text-transform: uppercase;
}

.statistics-block {
  margin-bottom: 35px;

  form {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    grid-gap: 5px;

    .c-forms__select {
      border: 1px solid $neutral40;
      padding-right: 50px;
      border-radius: 3px;
    }
    .c-forms__select select {
      padding-right: 50px;
      text-overflow: ellipsis;
    }

    [type='submit'] {
      line-height: inherit;
      font-family: inherit;
      border: 0;
      padding: 0;
      background-color: transparent;
      color: $neutral20;
      font-size: 1rem;
    }
  }
}

.filters {
  border: 1px solid $neutral40;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px; // same as h2 - will merge if available

  [hidden] {
    display: none;
  }

  &__toggle-button {
    text-align: inherit;
    width: 100%;
    display: block;
    padding: 0;
    position: relative;
  }

  &__headline {
    @extend %uppercase;
    font-size: 1rem;
    color: $neutral20;
    border-bottom: none;

    & > span {
      display: block;
    }
  }

  &__wrapper {
    margin-top: 20px;
  }

  &__chevron {
    position: absolute;
    right: -1px;
    transform: rotate(180deg);
    &--is-open {
      transform: rotate(0);
    }
  }

  &__group {
    border: 1px solid $neutral40;
    background-color: $secondary95;
    margin-bottom: 10px;
    border-radius: 2px;
    padding: 15px;
    position: relative;
    &--add {
      margin-bottom: 20px;
    }
    &--add:after {
      position: absolute;
      bottom: -19px;
      left: 50%;
      content: '';
      width: 22px;
      height: 22px;
      transform: rotate(45deg) translateX(-50%);
      border: 1px solid $neutral40;
      background-color: $secondary95;
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &--vertical {
      label {
        display: block;
        top: 3px; // ~3px
      }
      label ~ label {
        margin-top: 10px;
      }
    }
  }

  &__group-button {
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 15px;
    line-height: 1.5;
    text-transform: capitalize;

    &[aria-expanded='false'] {
      border-bottom: 1px solid $neutral40;

      &:after {
        content: url('../../framework/images/open.svg');
      }
    }

    &:after {
      content: url('../../framework/images/close.svg');
      position: absolute;
      right: 0;
    }
  }
  &__group-headline {
    color: $neutral20;
    @extend %uppercase;

    border-bottom: 1px solid $neutral40;
    display: flow-root;
    padding: 5px 0 15px;

    button {
      color: $accent40;
      margin-left: 10px;
      float: right;
      align-items: center;
    }
  }

  &__button-close-x {
    &:before {
      content: '';
      position: absolute;
      right: 15px;
      top: 34px;
      font-size: 20px;
      text-align: center;
      width: 14px;
      height: 1px;
      transform: rotate(45deg);
      background-color: $accent40;
    }
    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 34px;
      font-size: 20px;
      text-align: center;
      width: 14px;
      height: 1px;
      transform: rotate(-45deg);
      background-color: $accent40;
    }
  }
  &__clear {
    &:after {
      content: url('../../framework/images/delete.svg');
    }
  }

  &__clear-all {
    &:before {
      content: url('../../framework/images/delete.svg');
      margin-right: 5px;
      margin-top: 4px;
    }
  }

  &__icon-box-close {
    @include icon($pseudo: 'after', $content: '\e84e');
    &:after {
      font-size: 1.25em;
      position: relative;
      top: 1px;
    }
  }

  &__icon-box-plus {
    &:before {
      content: url('../../framework/images/add.svg');
      margin-right: 5px;
      margin-top: 4px;
    }
  }

  &__icon-box-minus {
    @include iconBox('before', '\e803');
  }

  &__lists {
    background-color: $white;
    border: 1px solid $neutral40;
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    height: 410px;
    display: flex;
    overflow-x: auto;
  }

  &__list {
    min-width: 200px;
    width: 33.33%;
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 0;
    margin: 0;
    border: 0 solid transparent;
    border-right: 1px solid $neutral40;
    overflow-y: auto;

    &--unstyled {
      list-style: none;
      padding: 0;

      li {
        margin-right: 20px;
      }
    }

    &--searchresult {
      width: 100%;
      border-right: none;
    }

    &:nth-child(3):last-child {
      border-right: none;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 3px solid transparent;
      background: $accent40;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      border-left: 1px solid $neutral40;
    }
  }

  &__listitem {
    font-size: 0.875rem;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
    /* a padded border bottom */
    box-shadow: 10px 0 0 white inset, -10px 0 0 white inset,
      0 -1px 0px 0px $neutral40 inset;

    &--selected {
      background-color: $secondary95;
      color: $white;
      box-shadow: none;
      /* hide the bottom border of previous element */
      outline: 1px solid $white;
    }
    &--has-children {
      padding-right: 20px;
    }
    &--has-children:before {
      color: $accent40;
      content: '›';
      position: absolute;
      width: 20px;
      display: inline-block;
      text-align: center;
      top: auto;
      right: 5px;
    }
  }
  &__checkbox {
    display: inline-block;
    position: absolute;
    top: auto !important;
    &::before {
      background-color: $secondary95;
      border: 1px solid $accent40 !important;
    }
    &:hover::before {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5) inset;
    }
  }

  .unstyled-formfield {
    border: none;
    padding: unset;
    margin: unset;
  }

  &__searchfield input {
    font-family: inherit;
    font-size: inherit;
    max-width: 100%;
    display: block;
    min-height: rem-calc(50);
    max-height: rem-calc(50);

    &:focus {
      background-color: $white;
      outline: none;
    }
  }

  &__searchfield [type='reset'] {
    width: rem-calc(22);
    height: rem-calc(22);
    line-height: rem-calc(22);
    font-size: rem-calc(18);
    background-color: transparent;
    text-align: center;
    border-radius: 50%;
  }

  &__searchfield {
    background-color: $white;
    border: 1px solid $neutral40;
    border-radius: rem-calc(25);
    margin-top: rem-calc(20);
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(385);
    text-align: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 17px;
    padding-right: 17px;

    @supports (display: grid) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: rem-calc(22) 1fr rem-calc(22);
    }

    &:focus-within {
      @include focus-style();
    }

    svg {
      width: rem-calc(22);
      height: rem-calc(22);

      path {
        fill: $neutral20;
      }
    }
  }

  &__label {
    display: inline-block;
    &:before {
      position: absolute !important;
      top: auto;
      left: 0;
    }
  }

  .break-with-manual-hyphens {
    word-break: break-all;
    hyphens: manual;
  }

  &__selected-values {
    display: grid;
    margin-top: rem-calc(15);
    grid-template-columns: 1fr 1fr;
    grid-gap: rem-calc(5) rem-calc(15);
  }

  &__remove-value {
    border: 1px solid $neutral40;
    background-color: $white;
    font-size: 0.875rem;
    border-radius: 2px;

    padding: 8px;
    text-align: left;
    position: relative;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      text-align: center;
      margin-left: 0;
    }
  }
}
