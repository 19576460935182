@import './core-functions.scss';

$primary20: #8e0c37;
$primary30: #7e2a4c;
$primary40: #a33662;
$primary50: #c03f73;
$secondary40: #e7dac5;
$secondary90: #f6f1e9;
$secondary95: #f9f6f1;
$accent30: #305a47;
$accent40: #40775e;
$accent90: #afd4c4;
$neutral20: #353636;
$neutral40: #727373;
$neutral90: #cccccc;
$neutral99: #f0f0f0;
$success40: #277637;
$success99: #e7f7ea;
$attention40: #ffd748;
$attention95: #fff7d9;
$error40: #db0f00;
$error99: #ffdad7;
$white: #ffffff;

/** TODO: The colors variable is conflicting from the $colors variable in inera-core-css. Add this when inera-core is removed. **/

$colors: (
  'primary20': #8e0c37,
  'primary30': #7e2a4c,
  'primary40': #a33662,
  'primary50': #c03f73,
  'secondary40': #e7dac5,
  'secondary90': #f6f1e9,
  'secondary95': #f9f6f1,
  'accent30': #305a47,
  'accent40': #40775e,
  'accent90': #afd4c4,
  'neutral20': #353636,
  'neutral40': #727373,
  'neutral90': #cccccc,
  'neutral99': #f0f0f0,
  'success40': #277637,
  'success99': #e7f7ea,
  'attention40': #ffd748,
  'attention95': #fff7d9,
  'error40': #db0f00,
  'error99': #ffdad7,
  'white': #ffffff,
  'grey-100': #f1f2f2,
  'grey-200': #9f9f9f,
  'grey-300': #808285,
  'grey-400': #696969,
  'grey-500': #636466,
  'grey-600': #484848,
  'grey-700': #353535,
  'grey-800': #1e1e1e,
  'grey-900': #000000,
  'black': #000000,
  'muted': #636466,
  'muted-dark': #636466,
  'light-grey': #f7f4f2,
  'medium-grey': #cacaca,
  'charcoal-grey': #666666,
  'dhusar-grey': #aaaaaa,
);
