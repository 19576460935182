@import '../../platformui/Linklist/styles.scss';

.c-footer__collapsible-lists {
  .c-anchor.c-linklist__link:before {
    // not sure where the linklist icon should have the 8px font-size defined.
    // but it's not working here
    font-size: inherit;
  }
}

.c-footer {
  .c-linklist {
    &__link {
      text-decoration: underline;

      &:focus {
        outline-color: $white;
      }
    }

    &--collapsible {
      ul {
        margin: 0;
      }

      li {
        border-bottom: 1px solid $white;
        padding: 11px 0 12px;
        width: auto;

        @include breakpoint(medium) {
          max-width: calc(100% - 20px);
          width: 100%;
        }

        margin-left: 20px;

        a {
          color: $white;
          text-decoration: none;
          padding-left: 0;

          @include breakpoint(medium) {
            margin-left: 0;
          }
        }
      }
    }

    &.footer-linklist {
      margin-top: -60px;

      li {
        padding: 0 0 10px 0;

        a {
          color: $white;
          overflow-wrap: anywhere;

          span {
            color: $white;
          }
        }
      }
    }

    &.c-linklist--site-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      li {
        display: block;
      }

      .c-footer__logo__text p a {
        display: inline;
      }

      @include breakpoint(medium) {

        li,
        a {
          border-bottom: 1px $white;
          color: $white;
          text-decoration: underline;
          display: inline-flex;
        }
      }

      @include breakpoint(large) {
        text-align: right;
        flex-direction: row-reverse;
        align-items: center;
      }

      .c-linklist__link {
        padding-left: 0;
        text-decoration: underline;
        font-size: rem-calc(14);
        margin-bottom: 8px;
      }

      .c-linklist {
        width: auto;
      }
    }

    &.c-linklist--no-icons {
      a {
        &:before {
          display: none;
          content: none;
        }
      }
    }

    .c-linklist__link:before {
      content: none;
    }

    .icon-arrow-rigt-ext-circle {
      &:before {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNi4wNDczM0MwIDIuNzMzNjIgMi42ODYyOSAwLjA0NzMzMjggNiAwLjA0NzMzMjhDNi4yNTQ5IDAuMDQ3MzMyOCA2LjQ2MTU0IDAuMjUzOTcxIDYuNDYxNTQgMC41MDg4NzFDNi40NjE1NCAwLjc2Mzc3MiA2LjI1NDkgMC45NzA0MSA2IDAuOTcwNDFDMy4xOTYwOSAwLjk3MDQxIDAuOTIzMDc3IDMuMjQzNDMgMC45MjMwNzcgNi4wNDczM0MwLjkyMzA3NyA4Ljg1MTI0IDMuMTk2MDkgMTEuMTI0MyA2IDExLjEyNDNDOC44MDM5MSAxMS4xMjQzIDExLjA3NjkgOC44NTEyNCAxMS4wNzY5IDYuMDQ3MzNDMTEuMDc2OSA1Ljc5MjQzIDExLjI4MzYgNS41ODU3OSAxMS41Mzg1IDUuNTg1NzlDMTEuNzkzNCA1LjU4NTc5IDEyIDUuNzkyNDMgMTIgNi4wNDczM0MxMiA5LjM2MTA0IDkuMzEzNzEgMTIuMDQ3MyA2IDEyLjA0NzNDMi42ODYyOSAxMi4wNDczIDAgOS4zNjEwNCAwIDYuMDQ3MzNaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTEuODQ3MiAwLjIwMTE3OUwxMS44NDcyIDIuNTA4ODdDMTEuODQ3MiAyLjc2Mzc3IDExLjY0MDYgMi45NzA0MSAxMS4zODU3IDIuOTcwNDFDMTEuMTMwOCAyLjk3MDQxIDEwLjkyNDIgMi43NjM3NyAxMC45MjQyIDIuNTA4ODdWMS44MTQ3MUw2LjQxNjUzIDYuMzIyMzNDNi4yMzYyOSA2LjUwMjU4IDUuOTQ0MDYgNi41MDI1OCA1Ljc2MzgyIDYuMzIyMzNDNS41ODM1OCA2LjE0MjA5IDUuNTgzNTggNS44NDk4NiA1Ljc2MzgyIDUuNjY5NjJMMTAuMzA5MiAxLjEyNDI2SDkuNTM4NUM5LjI4MzYgMS4xMjQyNiA5LjA3Njk2IDAuOTE3NjE4IDkuMDc2OTYgMC42NjI3MTdDOS4wNzY5NiAwLjQwNzgxNyA5LjI4MzYgMC4yMDExNzkgOS41Mzg1IDAuMjAxMTc5TDExLjg0NzIgMC4yMDExNzlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
      }
    }
  }
}
