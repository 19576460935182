iframe {
  max-width: 100%;
}

@mixin Video(
  $className: 'c-video',
  $marginBottom: 30px,
  $captionMargin: 11px 0 55px,
  $captionUnderlineColor: #000,
  $captionUnderlineHeight: 1px,
  $h2Color: #000,
  $captionFontSize: inherit
) {
  .#{$className} {
    margin-bottom: $marginBottom;
    width: 100%;

    @media print {
      margin-bottom: 10px;
      max-width: 780px;
    }

    &__heading {
      color: $h2Color;
    }

    &__container {
      background: $black;
      padding-top: 56.25%; //16:9
      position: relative;
      width: 100%;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border: none;
      }

      @media print {
        display: none;
      }
    }

    &__caption {
      margin: $captionMargin;
      position: relative;

      p {
        font-size: $captionFontSize;
      }

      &:after {
        background-color: $captionUnderlineColor;
        height: $captionUnderlineHeight;
        bottom: -10px;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        width: 87px;

        [dir='rtl'] & {
          left: auto;
          right: 0;
        }

        @include breakpoint(large) {
          bottom: -16px;
        }
      }

      @include breakpoint(medium) {
        width: 80%;
      }

      @include breakpoint(large) {
        width: 65%;
      }

      @media print {
        display: none;
      }
    }

    &__print-info {
      border: 1px solid #1a1a1a;
      border-radius: 10px;
      padding: 20px 50px;
      margin-bottom: 30px;

      @media print {
        display: block;
        max-width: 780px;

        p {
          margin: 0;
        }

        span {
          font-size: 8px;
        }
      }
    }

    //If video is standing format
    &.standing {
      @include breakpoint(large) {
        display: flex;
        flex-flow: row nowrap;
      }

      .#{$className}__container {
        width: 100%;
        padding-top: 178.05%;

        @include breakpoint(large) {
          width: 280px;
          min-width: 280px;
          height: 497px;
          padding-top: 0;
        }
      }

      .#{$className}__caption {
        margin-top: rem-calc(25);

        @include breakpoint(large) {
          margin: 0;
          padding-inline: rem-calc(24);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        p {
          margin-bottom: 0;
        }

        &::after {
          position: relative;
        }
      }
    }

    //Styling for videos in media gallery
    &.gallery {
      flex-flow: column;

      .#{$className}__container__wrapper {
        position: relative;

        &:after {
          background-color: $captionUnderlineColor;
          height: 1px;
          bottom: -10px;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          width: 87px;

          [dir='rtl'] & {
            left: auto;
            right: 0;
          }

          @include breakpoint(large) {
            bottom: -16px;
          }
        }
      }
      .#{$className}__caption {
        width: 100%;
        padding: 0;

        &:after {
          display: none;
        }
      }
      .c-linklist__link {
        font-size: rem-calc(16);
        margin-top: rem-calc(15);
        text-decoration: underline;
        z-index: 99;
      }
      &.standing {
        .#{$className}__container {
          @include breakpoint(large) {
            width: 305px;
            min-width: 305px;
            height: 541px;
          }
        }
      }
    }

    @content;
  }
}
