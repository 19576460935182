.c-formbutton {
  text-transform: uppercase;
  border-color: transparent;
  min-height: 50px;
  font-size: 1.25rem;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-weight: normal;
  min-width: 70px;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  justify-content: center;

  &--disabled,
  &:disabled {
    @extend .c-formbutton--primary-ghost;
    cursor: default;
    background-color: c('white');
    color: c('neutral20');
    border-color: c('muted');
    &:hover {
      background-color: c('white');
      color: c('neutral20');
      border-color: c('muted');
    }
  }
}

.c-formbutton--primary {
  color: c('white');
  background-color: c('accent40');
}

.c-formbutton--secondary {
  border: 1px solid c('accent40');
  background-color: c('white');
  color: c('accent40');
}

.c-formbutton--primary-ghost {
  color: c('accent90');
  background-color: c('white');
  border-color: c('accent90');
}

.c-formbutton__secondary-button {
  @extend .c-formbutton;
  @extend .c-formbutton--secondary;
  @extend .c-form__button;
}

.c-form__button {
  @extend .c-formbutton;
  @extend .c-formbutton--primary;
  box-sizing: border-box;
  line-height: 1;
  padding-bottom: 0;
  padding-top: 0;

  &--disabled,
  &:disabled {
    @extend .c-formbutton--primary-ghost;
    cursor: default;
    background-color: c('white');
    color: c('neutral20');
    border-color: c('muted');
    &:hover {
      background-color: c('white');
      color: c('neutral20');
      border-color: c('muted');
    }
  }
}
