@import '../../platformui/Localnav/styles';

@include Localnav(
  $borderColor: $neutral90,
  $componentMargin: 0 0 60px,
  $itemColor: $accent40,
  $borderMarkerWidth: 5px,
  $borderMarkerColor: $accent40,
  $selectedItemFontWeight: normal,
  $selectedItemColor: $accent40,
  $hasChildrenIconColor: $accent40
) {
  &__heading > a {
    @include base-font-family();

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: normal;
    color: $neutral20;
    padding-left: 0;
    padding-right: 0;
  }

  &__list {
    &__item {
      &--has-children > a:before {
        color: $accent40;
        transform: translateY(-50%) rotate(90deg);
        font-size: 25px;
      }
    }
    &__secondary {
      .c-localnav__list__item {
        a {
          padding-left: 40px;

          &:hover {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
