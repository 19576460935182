@import '../../platformui/ArticleHeader/styles';
@import '../../platformui/ArticleHeader/subject-icon';

@include ArticleHeader($headingColor: false, $paddingTop: 30px) {
  .c-articleheader {
    @include breakpoint(large) {
      padding-top: 20px;
      h1 {
        margin-bottom: 22px;
      }
    }

    &--has-regionlabel {
      @include breakpoint(large) {
        padding-top: 70px;
      }
    }
    &--alert {
      &:before {
        background: url('../../framework/images/alert-red.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        margin: 0 auto 27px;
        height: 50px;
        width: 50px;
        @include breakpoint(large) {
          height: 70px;
          width: 70px;
        }
      }
    }

    &__subject {
      color: $accent40;
      display: block;
      text-decoration: none;
      font-size: 14px;
      @include SubjectIcon($iconColor: $accent40);
      @include focus-handler($offset: 2px);

      @include breakpoint(large) {
        display: none;
      }

      &--hidden {
        display: none;
      }
    }

    &__translation {
      color: $black;
      display: block;
      font-size: rem-calc(16);
      font-style: italic;
      margin-bottom: 20px;
      margin-top: -20px;

      [dir='rtl'] & {
        text-align: right;
      }
    }
  }
}
