@mixin Teaser($headingLinkColor: c('black'),
  $vignetteColor: c('black'),
  $headingIconColor: c('black'),
  $headingPadding: 0,
  $headingMargin: 0 0 6px 0,
  $componentBoxShadow: 0 0 5px 0 rgba(c('black'), 0.2),
  $componentBorderRadius: false,
  $componentPageListBorderBottom: 10px solid $white,
  $componentPageListBorderBottomHover: 10px solid c('black'),
  $translationColor: c('black'),
  $translationTextTransform: uppercase,
  $translationFontSize: 14,
  $translationLineHeight: 1.43,
  $translationMargin: 7px 0 7px 0,
  $outerMargin: 20px,
  $eqHeightPadding: 20px 20px 2px,
  $headingFontWeight: 700,
  $backgroundColor: $white) {
  .c-teaser-outer {
    margin-bottom: $outerMargin;
    list-style-type: none;

    .u-columns--is-listview &,
    .u-flexwrap--is-listview & {
      border-radius: 25px;
      height: auto;
    }
  }

  a.c-teaser__heading {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .c-teaser {
    @include clearfix();
    background: $backgroundColor;
    box-shadow: $componentBoxShadow;
    height: 100%;
    display: block;
    overflow: hidden;
    text-align: left;

    @if ($componentBorderRadius !=false) {
      border-radius: nth($componentBorderRadius, 1);

      @include breakpoint(medium) {
        border-radius: nth($componentBorderRadius, 2);
      }

      @include breakpoint(large) {
        border-radius: nth($componentBorderRadius, 3);
      }
    }

    [dir='rtl'] & {
      text-align: right;
    }

    &:hover {
      text-decoration: none;
    }

    &__img-container {
      overflow: hidden;
      padding-top: 66.6667%;
      position: relative;

      @include breakpoint(medium) {

        .u-columns--is-listview &,
        .u-flexwrap--is-listview & {
          display: none;
        }
      }
    }

    //For sublinks
    &__img {
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;

      a:hover {
        @include focus-handler();
      }

      div,
      .c-image {
        background-color: rgba($black, 0.05);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
      }

      .c-image {
        opacity: 0;
        transition: opacity 0.3s ease;

        &--is-lazyloaded {
          opacity: 1;
        }
      }

      img {
        display: none;
      }
    }

    &__heading {
      margin: $headingMargin;
      padding: $headingPadding;
      font-weight: $headingFontWeight;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: none;
      }

      &[dir='rtl'] {
        text-align: right;
      }

      &__link {
        text-decoration: none;
        color: $headingIconColor;
        display: inline-block;
        position: relative;
        @include icon('e808');

        &:before {
          font-size: rem-calc(20);
          left: 0;
          margin-right: 7px;
          display: inline;

          [dir='rtl'] & {
            margin-left: 7px;
            margin-right: 0;
            display: inline-block;
            left: auto;
            right: 0;
            transform: rotateY(180deg);
          }
        }

        &:hover {
          color: $headingIconColor;
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }

        span {
          color: $headingLinkColor;
          overflow-wrap: anywhere;
        }

        @include breakpoint(medium) {

          .u-columns--is-listview &,
          .u-flexwrap--is-listview & {
            max-width: 100%;

            &:before {
              position: absolute;
              top: 1px;
            }

            span {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-left: 1.5rem;
            }
          }
        }
      }

      @include breakpoint(medium) {

        .u-columns--is-listview &,
        .u-flexwrap--is-listview & {
          border-bottom: none;
          margin: 0;
          padding: 0;
        }
      }
    }

    &__content {
      padding: 20px 20px 12px;
      width: 100%;

      @include breakpoint(medium) {

        .u-columns--is-listview &,
        .u-flexwrap--is-listview & {
          border-top: none;
          height: 50px;

          html:not([lang='sv']) & {
            height: auto;
          }

          @include breakpoint(medium) {
            padding: 12px 20px;
          }
        }
      }
    }

    &__text {
      p {
        font-size: inherit;
        margin-bottom: 10px;
      }

      @include breakpoint(medium) {

        .u-columns--is-listview &,
        .u-flexwrap--is-listview & {
          display: none;
        }
      }
    }

    &--eq-height {
      border-bottom: $componentPageListBorderBottom;

      @include breakpoint(medium) {
        transition: transform 0.15s ease-out, box-shadow 0.15s ease-out,
          border-bottom 0.15s ease-out;

        &:hover,
        &:focus {
          border-bottom: $componentPageListBorderBottomHover;
          box-shadow: 0 4px 8px 0 rgba($black, 0.2);
          transform: translateY(-10px);
          opacity: 1;
          padding-bottom: 0;

          .u-flexwrap--is-listview & {
            box-shadow: $componentBoxShadow;
            transform: none;
          }
        }
      }

      .c-teaser__content {
        float: left;
        padding: $eqHeightPadding;
        position: relative;
        width: 100%;

        .u-flexwrap--is-listview & {
          padding: 13px 20px;
        }

        [dir='rtl'] & {
          float: right;
        }

        @include breakpoint(large) {
          float: none;
          top: 0;
          transform: none;
        }
      }

      .c-teaser__heading__link {
        text-decoration: none;

        &:hover {
          span {
            text-decoration: none;
          }
        }
      }

      .c-teaser__img-container {
        &>div {
          background-color: rgba($black, 0.05);
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }

        .c-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .c-image {
          opacity: 0;
          transition: opacity 0.3s ease;

          &--is-lazyloaded {
            opacity: 1;
          }
        }
      }

      &.c-teaser--has-img {
        @include breakpoint(medium) {

          .u-columns--is-listview &,
          .u-flexwrap--is-listview & {
            height: auto;
          }
        }
      }
    }

    &__translation {
      color: $translationColor;
      display: block;
      font-size: rem-calc($translationFontSize);
      line-height: $translationLineHeight;
      margin: $translationMargin;
      text-transform: $translationTextTransform;
    }

    @content;
  }

  .u-columns--is-listview {
    li.columns {
      margin-top: 5px;
      margin-bottom: 20px;

      &>.c-teaser {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .c-teaser-videoicon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-Index: 99;
  }
}

.row {
  .columns {
    .c-section__header {
      .c-section__heading {
        margin: 10px 0 20px;
      }
    }
  }
}