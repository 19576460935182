@import '../forms/button.scss';

.reading-aid-block {
  margin: 40px 0;

  @media print {
    display: none;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;

    button {
      @extend .c-formbutton__secondary-button;
      font-size: 1.25rem;
      width: 100%;
    }
  }
}
