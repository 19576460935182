@import '../../platformui/Section/styles';

@include Section(
  $headingJustify: center,
  $marginJustify: 30px,
  $headingMaxWidth: none,
  $sectionPadding: (
    30px 0 30px,
    30px 0 30px,
    30px 0 30px,
  )
) {
  &__double-column {
    @include breakpoint(large) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 20px;
      padding-bottom: 30px;
      padding-inline: rem-calc(10);
      border-top: 1px solid $neutral90;
      max-width: 80rem;
      margin: auto;

      .c-section {
        > .row {
          border: 1px solid $secondary40 !important;
        }
      }
    }

    .c-section__header {
      width: 100%;

      &--hidden {
        margin: 30px;
      }

      .c-section__heading {
        margin-top: 40px;
        font-size: 1rem;
      }
    }

    .c-section {
      > .row {
        @include breakpoint(large) {
          padding: 0 30px;
          border-radius: 5px;

          .columns {
            .c-section__inner {
              border-top: none !important;
            }
          }
        }
      }
    }

    section {
      @include breakpoint(large) {
        width: calc(100% / 2 - 10px);
        margin: 0;
      }
    }
  }

  &--navigation {
    .c-section__inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__heading {
    @include base-font-family();
    font-size: rem-calc(24);
    font-weight: normal;
    text-transform: uppercase;
    color: $neutral20;
  }

  &.c-section__heading--center {
    .c-section__header {
      .c-section__heading {
        font-size: 1rem;
        letter-spacing: 1px;
        margin: 10px 0 20px;
        color: $neutral20;
        max-width: none;
      }

      &--double-column {
        margin: 0;

        @include breakpoint(large) {
          justify-content: start;

          h2 {
            margin: 0 !important;
          }
        }
      }
    }

    .row {
      .c-section__inner {
        padding: 30px 0;
      }
    }

    .link-item-list__button {
      border-color: $accent40;

      span {
        color: $accent40;
        font-weight: normal;
      }

      &:hover,
      &:active {
        border-color: $accent30;
        background-color: $accent30;
      }

      &--double-column {
        color: $accent40;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .c-section__inner {
      border-top: 1px solid $neutral90;

      &--double-column {
        border: none;
      }
    }
  }

  .page--collection-page & {
    .c-section__inner > .row > .columns {
      @include breakpoint(small only) {
        /* This is quick fix for aligning text in the content with the intro. See https://inera.atlassian.net/browse/K2IVT-850 */
        padding-inline: rem-calc(10px);
      }
    }
  }
}

.subject-intro + .c-section {
  .c-section__inner {
    border-top: none;
  }
}

.c-section--intro + .c-section {
  .c-section__inner {
    border-top: 1px solid $neutral90;
  }
}

.c-section__header {
  margin-bottom: 0 !important;
}
