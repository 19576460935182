@import '../../platformui/Buttons/LinkButton/link-button.scss';

@mixin HighLight(
  $infoIcon: '#',
  $importantInfoIcon: '#',
  $importantIcon: '#',
  $routinesIcon: '#',
  $recommendationsIcon: '#',
  $localInstructionsIcon: '#',
  $warningIcon: '#',
  $margin: (
    0 -20px 30px,
    0 -40px 30px -40px,
  ),
  $padding: (
    20px 20px 1px,
    30px 40px 10px,
  )
) {
  .c-highlight {
    clear: both;
    margin: nth($margin, 1);
    padding: nth($padding, 1);

    &__text-container {
      word-wrap: break-word;
    }

    @media print {
      border: 1px solid $black;
      margin: 0 0 30px !important;
      page-break-inside: avoid;
      max-width: 770px;
      print-color-adjust: exact; //Firefox
      -webkit-print-color-adjust: exact; //Chrome, Safari, Edge
    }

    @include breakpoint(medium) {
      margin: nth($margin, 2);
      padding: nth($padding, 2);
    }

    &__cta-container {
      display: flex;
      flex-direction: column;
      gap: rem-calc(20);

      @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &__cta-primary-button {
        @include LinkButton();
      }

      &__cta-secondary-button {
        @include LinkButton();
      }
    }

    &--info {
      background-image: url('#{$infoIcon}');
    }

    &--importantinfo {
      background-image: url('#{$importantInfoIcon}');
    }

    &--important {
      background-image: url('#{$importantIcon}');
    }

    &--routines {
      background-image: url('#{$routinesIcon}');
    }

    &--recommendations {
      background-image: url('#{$recommendationsIcon}');
    }

    &--localinstructions {
      background-image: url('#{$localInstructionsIcon}');
    }

    &--warning {
      background-image: url('#{$warningIcon}');
    }

    @content;
  }
}
