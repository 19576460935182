@import '../../platformui/SubjectIntro/styles';

@include SubjectIntro(
  $backgroundColor: $secondary90,
  $subjectColor: $neutral20,
  $subjectTextColor: $primary40,
  $linkListColor: $accent40,
  $linkListHoverColor: $accent30,
  $imageMaxWith: 1020px,
  $height: 400px,
  $backgroundImage:
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjM4NiIgdmlld0JveD0iMCAwIDMyMCAzODYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjE2MCIgY3k9IjU2NCIgcj0iNTY0IiBmaWxsPSIjRjZGMUU5Ii8+Cjwvc3ZnPgo=')
) {
}
.subject-intro {
  z-index: 0;
  background-color: $secondary90;

  @include breakpoint(large) {
    &::after {
      content: none;
    }
  }

  & > .row.expanded {
    max-width: rem-calc(1440);
    margin-left: auto;
    margin-right: auto;
  }

  .large-7 {
    float: right;
  }

  &__image-container {
    float: none;
    &:after {
      content: none;
    }
    @include breakpoint(large) {
      &:before {
        content: none;
      }
    }
  }
  &__subject-heading {
    margin: 20px 0 10px;

    @include breakpoint(medium) {
      margin: 50px 0 10px;
    }
    @include breakpoint(large) {
      margin: 0 0 10px;
      font-size: rem-calc(40);
      line-height: 48px;
    }
    font-size: 2rem;
    line-height: 38px;
  }
  &__preamble {
    p {
      font-size: 1rem;
    }
  }

  &__text {
    background-repeat: no-repeat;
    background-position: top;
    top: -55px;
    background-size: 300vw;
    padding-top: 20px;

    &:after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjQwMiIgdmlld0JveD0iMCAwIDMyMCA0MDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNjAgMTE2MEM0ODAuMzI2IDExNjAgNzQwIDkwMC4zMjYgNzQwIDU4MEM3NDAgMjU5LjY3NCA0ODAuMzI2IDAgMTYwIDBDLTE2MC4zMjYgMCAtNDIwIDI1OS42NzQgLTQyMCA1ODBDLTQyMCA5MDAuMzI2IC0xNjAuMzI2IDExNjAgMTYwIDExNjBaTTE2MCA3OTUuMTYxQzI3OC44MzEgNzk1LjE2MSAzNzUuMTYxIDY5OC44MzEgMzc1LjE2MSA1ODBDMzc1LjE2MSA0NjEuMTY5IDI3OC44MzEgMzY0LjgzOSAxNjAgMzY0LjgzOUM0MS4xNjg5IDM2NC44MzkgLTU1LjE2MTMgNDYxLjE2OSAtNTUuMTYxMyA1ODBDLTU1LjE2MTMgNjk4LjgzMSA0MS4xNjg5IDc5NS4xNjEgMTYwIDc5NS4xNjFaIiBmaWxsPSIjRjZGMUU5Ii8+Cjwvc3ZnPgo=');
      background-size: 300vw;
      background-position: top;
      background-color: transparent;
      left: 0;
      right: 0;
      top: -20px;
      height: 100%;
      z-index: 1;
    }

    @include breakpoint(medium) {
      top: -80px;
    }

    @include breakpoint(large) {
      padding-bottom: 30px;
      top: 0;

      &:after {
        background-image: url('data:image/svg+xml,%3csvg width="639" height="400" viewBox="0 0 639 400" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M209 651C446.483 651 639 458.483 639 221C639 -16.4828 446.483 -209 209 -209C-28.4828 -209 -221 -16.4828 -221 221C-221 458.483 -28.4828 651 209 651ZM209 380.516C297.099 380.516 368.516 309.099 368.516 221C368.516 132.901 297.099 61.4839 209 61.4839C120.901 61.4839 49.4839 132.901 49.4839 221C49.4839 309.099 120.901 380.516 209 380.516Z" fill="%23F6F1E9"/%3e%3ccircle cx="209" cy="221" r="400" fill="%23F6F1E9"/%3e%3c/svg%3e');
        background-position: right;
        background-size: cover;
        top: 0;
        left: 100px;
      }
    }

    &__content {
      margin: 0 auto;
      padding: 0 51px 0px 51px;
      z-index: 1;
      text-align: center;

      @include breakpoint(large) {
        margin-left: 60px;
        padding: 0;
      }

      @include breakpoint(xlarge) {
        text-align: unset;
        margin-left: 140px;
        padding-right: 20px;
      }
    }
    .row.expanded {
      min-width: 100%;
      z-index: 3;
    }
  }

  &-linklist {
    ul {
      @include breakpoint(large) {
        display: flex;
      }
      display: block;
    }
    li {
      margin: 0;
    }

    &__link {
      width: 100%;
      border-radius: 3px;
      padding: 0 30px;

      font-weight: normal;
      &:focus {
        background-color: $accent30;
      }
      span {
        @include base-font-family();
        font-size: rem-calc(20);
        font-weight: normal;
      }
    }
  }
}
